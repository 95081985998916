import { type Provider } from '@mate-academy/react-di';
import YouTube from 'react-youtube';
import { YouTubeMock } from '@/components/platform/Learn/pages/CourseTopic/containers/YouTubeMock';
import { LocationSelector } from '@/components/ui/FormElements/FormInputs/GoogleLocationSelector/GoogleLocationSelector';
import { CountrySelector } from '@/components/ui/FormElements/FormInputs/GoogleCountrySelector/GoogleCountrySelector';
import { CitySelector } from '@/components/ui/FormElements/FormInputs/GoogleCitySelector/GoogleCitySelector';
import {
  GoogleLocationSelectorMock,
} from '@/components/ui/FormElements/FormInputs/GoogleSelectorMock/GoogleLocationSelector.mock';
import {
  GoogleCountrySelectorMock,
} from '@/components/ui/FormElements/FormInputs/GoogleSelectorMock/GoogleCountrySelector.mock';
import {
  GoogleCitySelectorMock,
} from '@/components/ui/FormElements/FormInputs/GoogleSelectorMock/GoogleCitySelector.mock';

export const cypressProviders: Provider[] = [
  {
    value: YouTubeMock,
    token: YouTube,
  },
  {
    value: GoogleLocationSelectorMock,
    token: LocationSelector,
  },
  {
    value: GoogleCountrySelectorMock,
    token: CountrySelector,
  },
  {
    value: GoogleCitySelectorMock,
    token: CitySelector,
  },
];
