import { type InMemoryCache, type Resolver } from '@apollo/client';
import { vacancyEditorState } from '@/controllers/platform/vacancyEditor/vacancyEditor.queries';
import { type ToggleVacancyEditorMutationVariables } from '@/controllers/platform/vacancyEditor/vacancyEditor.typedefs';

export const VacancyEditorResolvers = {
  Mutation: {
    toggleVacancyEditor: async (
      parent: Resolver,
      { open, vacancyId }: ToggleVacancyEditorMutationVariables,
      { cache }: { cache: InMemoryCache },
    ) => {
      cache.writeQuery({
        query: vacancyEditorState,
        data: {
          vacancyEditor: {
            open,
            vacancyId,
            __typename: 'VacancyEditor',
          },
        },
      });

      return open;
    },
  },
};
