import { LogLevels } from '@mate-academy/logger';
import { MessageError, MessageFragmentName, MessageType } from '@/components/platform/Chat/chat.typedefs';
import { errorHandler } from '@/core/ErrorHandler';
import {
  addMessageToThread,
  getChatFromCache,
  incrementUnreadChatMessagesCount,
  updateChatLastActionTime,
  updateParticipantLastActionTime,
} from '@/components/platform/Chat/store.helpers';
import { addMessageToQuery } from '@/components/platform/Chat/cache.helpers/chatMessagesQuery.helper';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import { MessageFragmentDoc } from '@/components/platform/Chat/graphql/generated/message.fragment.generated';
import {
  OpenQuestionAnswerFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/openQuestionAnswer.fragment.generated';
import { PollAnswerFragmentDoc } from '@/components/platform/Chat/graphql/generated/pollAnswer.fragment.generated';
import { logger } from '@/core/Logger';
import { ChatCacheHelper } from '@/components/platform/Chat/cache.helpers/chat.helper';
import {
  useChatMessageCreatedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageCreatedWS.subscription.generated';
import { MessageWSToCommonTypeMap } from '@/components/platform/Chat/chat.constants';
import {
  type AbstractMessageFragment,
  AbstractMessageFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/abstractMessage.fragment.generated';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { exists } from '@/lib/helpers/functional';

interface Params {
  skip?: boolean;
}

export const useChatMessageCreatedWS = ({ skip }: Params = {}) => {
  const [authUser] = useAuthUser({ ssr: false });

  useChatMessageCreatedWsSubscription({
    skip: !isBrowser || skip,
    onData: async ({
      client,
      data: { data },
    }) => {
      const messageCreatedData = data?.chatMessageCreatedWS.message;

      const isOwn = (
        exists(authUser)
          && data?.chatMessageCreatedWS.senderUserId === authUser.id
      );

      if (!messageCreatedData?.__typename) {
        return;
      }

      const cachedEntityType = (
        MessageWSToCommonTypeMap[messageCreatedData.__typename]
      );

      const cachedId = `${cachedEntityType}:${messageCreatedData.id}`;

      client.cache.writeFragment({
        id: cachedId,
        fragment: AbstractMessageFragmentDoc,
        fragmentName: MessageFragmentName.AbstractMessage,
        data: {
          ...messageCreatedData,
          isOwn,
          __typename: cachedEntityType,
          ...(
            messageCreatedData.__typename === 'ThreadWS'
              ? { receivedXp: null }
              : {}
          ),
        },
      });

      const normalizedMessage = client.cache.readFragment<
        AbstractMessageFragment
      >({
        id: cachedId,
        fragment: AbstractMessageFragmentDoc,
        fragmentName: MessageFragmentName.AbstractMessage,
      });

      if (!normalizedMessage) {
        return;
      }

      const { cache } = client;

      const { chatId, createdAt } = normalizedMessage;

      switch (normalizedMessage.__typename) {
        case MessageType.Thread:
        case MessageType.OpenQuestion:
        case MessageType.SystemMessage:
        case MessageType.Poll: {
          addMessageToQuery(
            cache,
            normalizedMessage,
          );

          if (isOwn) {
            const chat = getChatFromCache(cache, chatId);

            updateParticipantLastActionTime({
              store: client.cache,
              participantId: chat?.myParticipant?.id,
              lastActionTime: createdAt,
              chatId,
            });
          }

          incrementUnreadChatMessagesCount(cache, normalizedMessage);

          updateChatLastActionTime(cache, chatId, createdAt);

          const chatCacheHelper = new ChatCacheHelper(cache);

          await chatCacheHelper.loadChatAndAddToList(client, chatId);
          await chatCacheHelper.addChatToGroupIfNotExists(client, chatId);

          break;
        }

        case MessageType.Message: {
          const messageFields = {
            message: normalizedMessage,
            fragment: MessageFragmentDoc,
            fragmentName: MessageFragmentName.Message,
          };
          const threadFields = {
            type: MessageType.Thread,
            fieldToUpdate: 'messages',
            shouldIncrementRepliesCount: true,
          };

          addMessageToThread(cache, threadFields, messageFields);

          break;
        }

        case MessageType.OpenQuestionAnswer: {
          const messageFields = {
            message: normalizedMessage,
            fragment: OpenQuestionAnswerFragmentDoc,
            fragmentName: MessageFragmentName.OpenQuestionAnswer,
          };
          const threadFields = {
            type: MessageType.OpenQuestion,
            fieldToUpdate: 'answers',
          };

          addMessageToThread(cache, threadFields, messageFields);

          break;
        }

        case MessageType.PollAnswer: {
          const messageFields = {
            message: normalizedMessage,
            fragment: PollAnswerFragmentDoc,
            fragmentName: MessageFragmentName.PollAnswer,
          };
          const threadFields = {
            type: MessageType.Poll,
            fieldToUpdate: 'votes',
          };

          addMessageToThread(cache, threadFields, messageFields);

          break;
        }

        default:
          errorHandler.captureException(
            new Error(MessageError.UnknownMessageType),
            {
              logger: logger.child('useChatMessageReceived'),
              logLevel: LogLevels.Error,
              fields: normalizedMessage,
            },
          );

          break;
      }
    },
  });
};
