import type { Ref } from 'react';
import { forwardRef } from 'react';
import ReactSelectAsync, { type Async, type Props } from 'react-select/async';
import { type ValueType } from 'react-select';
import { cn } from '@/lib/classNames';
import { type SelectOption } from '@/components/ui/FormElements/FormInputs/Select/Select.typedefs';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import styles from './Select.module.scss';

export const SelectAsyncUi = forwardRef((
  props: Props<SelectOption<any>, false>,
  ref: Ref<Async<SelectOption<any>>>,
) => {
  const { className, ...rest } = props;

  return (
    <ReactSelectAsync
      className={cn(
        styles.select,
        styles.regularMode,
        className,
      )}
      ref={ref}
      classNamePrefix="ma-select"
      closeMenuOnSelect={!props.isMulti}
      {...rest}
    />
  );
});

export const SelectAsync = withFinalFormController<
  ValueType<SelectOption<any>, false>
>()(SelectAsyncUi);
