import {
  type SubDomainFeatureConfig,
} from '@/controllers/features/subDomainFeature.typedefs';
import { SubDomains } from '@/constants/subdomains';
import { Features } from '@/controllers/features/features.constants';

const {
  usa,
  us,
  ua,
  uk,
} = SubDomains;

export const SubDomainFeatures = {
  EnglishEventTab: Features.EnglishEventTab,
  WebinarsEventTab: Features.WebinarsEventTab,
  EnglishTab: Features.EnglishTab,
  EnglishLevelInfoInProfile: Features.EnglishLevelInfoInProfile,
  MentorTaskReview: Features.MentorTaskReview,
  SoftDeadlines: Features.SoftDeadlines,
  LmsContentSearch: Features.LmsGlobalSearch,
  DouAwardsBanner: Features.DouAwardsBanner,
  LocaleSelectorsInSettings: Features.LocaleSelectorsInSettings,
};

const CONFIG: SubDomainFeatureConfig[] = [
  {
    name: SubDomainFeatures.EnglishEventTab,
    description: 'English tab on events page',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.WebinarsEventTab,
    description: 'Webinar tab on events page',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.EnglishTab,
    description: 'English tab in the navigation menu',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.EnglishLevelInfoInProfile,
    description: 'English level info in the profile',
    filter: { disabledIn: [usa] },
  },
  {
    name: SubDomainFeatures.MentorTaskReview,
    description: 'Mentor task review for GH tasks',
    filter: { disabledIn: [us] },
  },
  {
    name: SubDomainFeatures.LmsContentSearch,
    description: 'Platform content search',
    filter: { disabledIn: [us] },
  },
  {
    name: SubDomainFeatures.SoftDeadlines,
    description: 'Soft deadlines for flex students',
    filter: { disabledIn: [us] },
  },
  {
    name: SubDomainFeatures.DouAwardsBanner,
    description: 'Dou Awards banner on the learn page',
    filter: { enabledIn: [ua] },
  },
  {
    name: SubDomainFeatures.LocaleSelectorsInSettings,
    description: 'Locale selectors in settings',
    filter: { disabledIn: [uk, usa] },
  },
];

const SUB_DOMAIN_FEATURES = {
  config: CONFIG,
};

export default SUB_DOMAIN_FEATURES;
