import { type FeatureWrapper } from '@/typedefs/common';

export const ComponentWrapper: FeatureWrapper = (
  {
    children,
    wrapper,
    shouldWrapChildren,
  },
) => (
  shouldWrapChildren
    ? wrapper(children)
    : children
);
