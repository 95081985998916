import type { CourseTopic } from '@/controllers/courseTopics/courseTopics.typedefs';
import { type TopicStatus } from '@/controllers/graphql/generated';
import { type TestTaskLinkFragment } from '@/components/platform/TestTasks/graphql/generated/testTaskLink.fragment.generated';

export enum TopicSections {
  Practice = 'PRACTICE',
  Theory = 'THEORY',
  Video = 'VIDEO',
}

// TODO: avoid using TestTaskLinkFragment
export interface CourseUserTopic {
  id: number;
  courseTopic: CourseTopic;
  currentSection: TopicSections;
  testTaskLink: TestTaskLinkFragment;
  completed: boolean;
  completionRate: number;
  status: TopicStatus;
}

export type TopicPageQueryParams = {
  courseSlug: string;
  courseTopicSlug: string;
  section: string;
};
