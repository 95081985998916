import { type InMemoryCache } from '@apollo/client';
import { sidebarState } from '@/controllers/platform/sidebar/sidebar.queries';
import { vacancyEditorState } from '@/controllers/platform/vacancyEditor/vacancyEditor.queries';

export const setInitialState = (cache: InMemoryCache) => {
  cache.writeQuery({
    query: sidebarState,
    data: {
      sidebarState: {
        sidebarOpen: false,
      },
    },
  });

  cache.writeQuery({
    query: vacancyEditorState,
    data: {
      open: false,
      vacancyId: null,
    },
  });
};
