import type { DetailedHTMLProps, InputHTMLAttributes, Ref } from 'react';
import { forwardRef } from 'react';
import { cn } from '@/lib/classNames';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { typography } from '@/components/ui/typography';
import styles from './InputRadio.module.scss';

interface Props extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  > {
  label?: string;
  inputClassName?: string;
  inputDecorationClassName?: string;
  color?: InputRadioMode;
  dataQA?: string;
}

export enum InputRadioMode {
  Primary = 'primary',
  Green = 'green',
  Red = 'red',
  Purple = 'purple',
  Gray = 'gray',
}

export const colorClassNames: Record<InputRadioMode, string> = {
  [InputRadioMode.Primary]: styles.radioInputPrimary,
  [InputRadioMode.Green]: styles.radioInputGreen,
  [InputRadioMode.Red]: styles.radioInputRed,
  [InputRadioMode.Purple]: styles.radioInputPurple,
  [InputRadioMode.Gray]: styles.radioInputGray,
};

export const InputRadioUI = forwardRef(
  (props: Props, ref: Ref<HTMLInputElement>) => {
    const {
      className,
      label,
      dataQA,
      inputDecorationClassName,
      color = InputRadioMode.Primary,
      ...rest
    } = props;

    return (
      <label className={cn(
        styles.radioContainer,
        typography.platformTextMain,
        { [styles.radioContainerDisabled]: props.disabled },
        colorClassNames[color],
        className,
      )}
      >
        <input
          data-qa={dataQA}
          className={styles.radioInput}
          type="radio"
          ref={ref}
          {...rest}
        />

        <div className={cn(styles.radioDecoration, 'inputDecoration', inputDecorationClassName)}>
          <div className={cn(styles.radioIcon, 'inputIcon')} />
        </div>

        <span
          data-qa="option-radiobutton"
          className={cn('inputLabel', styles.label)}
        >
          {label}
        </span>
      </label>
    );
  },
);

export const InputRadio = withFinalFormController(
  { type: 'radio' },
)(InputRadioUI);
