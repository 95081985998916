import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  LoadedParticipantsStoreDocument,
  type LoadedParticipantsStoreQuery,
} from '@/components/platform/Chat/graphql/generated/loadedParticipantsStore.query.generated';
import {
  useChatParticipantEnteredSubscription,
} from '@/components/platform/Chat/graphql/generated/participantEntered.subscription.generated';

export const useChatParticipantEntered = () => {
  useChatParticipantEnteredSubscription({
    skip: !isBrowser,
    onData: ({
      client: { cache: store },
      data: { data },
    }) => {
      const createdParticipants = data?.chatParticipantEntered;

      if (!createdParticipants) {
        return;
      }

      const presentCache = store.readQuery<LoadedParticipantsStoreQuery>({
        query: LoadedParticipantsStoreDocument,
        variables: {
          chatId: createdParticipants.chatId,
        },
      });

      if (!presentCache?.chatParticipants) {
        return;
      }

      const existingParticipants = presentCache?.chatParticipants || [];

      store.writeQuery<LoadedParticipantsStoreQuery>({
        query: LoadedParticipantsStoreDocument,
        variables: {
          chatId: createdParticipants.chatId,
        },
        data: {
          chatParticipants: [...existingParticipants, createdParticipants],
        },
      });
    },
  });
};
