import { memo, type FC } from 'react';
import { useChatSubscriptions } from '@/components/platform/Chat/hooks/useChatSubscriptions';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import { useLeaderboardSubscriptions } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/hooks/useLeaderboardSubscriptions';
import { type AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import { useUserOnlineSubscription } from '@/controllers/user/generated/userOnline.subscription.generated';

interface Props {
  authUser: AuthUserFragment;
}

export const AuthUserPlatformWideSubscriptions: FC<Props> = memo(
  ({ authUser }) => {
    useUserOnlineSubscription({
      skip: !isBrowser,
    });

    useChatSubscriptions(authUser);

    useLeaderboardSubscriptions();

    return null;
  },
);
