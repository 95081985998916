import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconMedalSilver: FCIcon = (props) => (
  <BaseIcon
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_53086_7015)">
      <path d="M14.5 6.22222L9.05556 0H0.5L11.3889 13.2222L20.3497 9.52778L14.5 6.22222Z" fill="#E25544" />
      <path d="M18.5481 12.4647C18.6103 12.2959 18.65 12.1162 18.65 11.9257C18.65 11.067 17.9531 10.3701 17.0945 10.3701H11.909C11.0496 10.3701 10.3535 11.067 10.3535 11.9257C10.3535 12.1162 10.3923 12.2959 10.4553 12.4647C7.92134 13.8849 6.20557 16.5923 6.20557 19.7035C6.20479 24.2853 9.91946 28 14.5013 28C19.0832 28 22.7979 24.2853 22.7979 19.7035C22.7979 16.5923 21.0821 13.8849 18.5481 12.4647Z" fill="#C8CCCE" />
      <path d="M17.6126 22.4942H14.857C15.5399 21.5344 16.733 19.9788 17.1981 19.149C17.5473 18.4653 17.7651 18.0297 17.7651 17.259C17.7651 15.6443 16.544 14.3213 14.4642 14.3213C12.7336 14.3213 11.5553 15.7462 11.5553 15.7462C11.4534 15.8628 11.4682 16.0371 11.5701 16.125L12.4575 17.0264C12.5734 17.1431 12.7484 17.1431 12.8643 17.0264C13.1404 16.7207 13.6786 16.2992 14.2744 16.2992C15.0888 16.2992 15.5546 16.7931 15.5546 17.3461C15.5546 17.7528 15.3656 18.1604 15.1766 18.4513C14.318 19.7595 12.1659 22.6832 11.3228 23.7884V24.0793C11.3228 24.2255 11.4386 24.3554 11.5989 24.3554H17.6126C17.7573 24.3554 17.8888 24.2247 17.8888 24.0793V22.7711C17.8895 22.6248 17.7573 22.4942 17.6126 22.4942Z" fill="#627077" />
      <path d="M19.9444 0L14.5 6.22222L18.6922 11.9093L19.6466 10.7512L28.5 0H19.9444Z" fill="#E77062" />
    </g>
    <defs>
      <clipPath id="clip0_53086_7015">
        <rect width="28" height="28" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </BaseIcon>
);
