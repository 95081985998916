import { type ChatAccessType, type ChatKind, type LeaderboardLeagueType } from '@/controllers/graphql/generated';
import { type ChatMessageUploadFile } from '@/components/ui/ChatInput/chatInput.typedefs';
import {
  type SubscribedThreadFragment,
} from '@/components/platform/Chat/graphql/generated/subscribedThread.fragment.generated';
import { type ChatBuddyFragment } from '@/components/platform/Chat/graphql/generated/chatBuddy.fragment.generated';
import { type ThreadFragment } from '@/components/platform/Chat/graphql/generated/thread.fragment.generated';
import { type OpenQuestionFragment } from '@/components/platform/Chat/graphql/generated/openQuestion.fragment.generated';
import { type PollFragment } from '@/components/platform/Chat/graphql/generated/poll.fragment.generated';
import { type SystemMessageFragment } from '@/components/platform/Chat/graphql/generated/systemMessage.fragment.generated';
import { type PollAnswerFragment } from '@/components/platform/Chat/graphql/generated/pollAnswer.fragment.generated';
import {
  type OpenQuestionAnswerFragment,
} from '@/components/platform/Chat/graphql/generated/openQuestionAnswer.fragment.generated';
import { type MessageFragment } from '@/components/platform/Chat/graphql/generated/message.fragment.generated';

export enum MessageType {
  Message = 'Message',
  Thread = 'Thread',
  OpenQuestion = 'OpenQuestion',
  OpenQuestionAnswer = 'OpenQuestionAnswer',
  Poll = 'Poll',
  PollAnswer = 'PollAnswer',
  SystemMessage = 'SystemMessage',
  DraftMessage = 'DraftMessage',
}

export enum MessageWSType {
  Message = 'MessageWS',
  Thread = 'ThreadWS',
  OpenQuestion = 'OpenQuestionWS',
  OpenQuestionAnswer = 'OpenQuestionAnswerWS',
  Poll = 'PollWS',
  PollAnswer = 'PollAnswerWS',
  SystemMessage = 'SystemMessageWS',
  DraftMessage = 'DraftMessageWS',
}

export type ThreadMessageTypes = (
  ThreadFragment | OpenQuestionFragment | PollFragment | SystemMessageFragment
);
export type ChildMessageTypes = (
  MessageFragment | OpenQuestionAnswerFragment | PollAnswerFragment
);

export enum MessageOrientation {
  Right = 'Right',
  Left = 'Left',
}

export enum ScrollPosition {
  Top = 'Top',
  Bottom = 'Bottom',
}

export enum MessageFragmentName {
  Message = 'Message',
  Thread = 'Thread',
  OpenQuestion = 'OpenQuestion',
  OpenQuestionAnswer = 'OpenQuestionAnswer',
  Poll = 'Poll',
  PollAnswer = 'PollAnswer',
  AbstractMessage = 'AbstractMessage',
  PollOption = 'PollOption',
  SystemMessage = 'SystemMessage',
}

export enum ChatTypeName {
  GroupChat = 'GroupChat',
  PrivateChat = 'PrivateChat'
}

export enum MessageError {
  UnknownMessageType = 'Unknown message type',
}

export interface ChatMinimal {
  id: number;
  name: string;
  kind: ChatKind;
  accessType: ChatAccessType;
  description?: string | null;
  chatBuddy?: ChatBuddyFragment | null;
  isChatWithSelf?: boolean;
}

export enum ChatListFilter {
  AllActivity,
  UnreadsOnly,
  MentionsOnly,
}

export enum ChatInfoTab {
  General = 'general',
  Members = 'members',
  Settings = 'settings',
}

export enum SortOption {
  Date = 'Date&time',
  IsCorrect = 'Correct/wrong',
}

export enum ChatExtension {
  Statistics = 'statistics',
  Guidelines = 'guidelines',
}

export type FetchMoreMessagesFn = (
  cursor: number,
  onCompleted?: () => void
) => Promise<void>;

export type CreateMessageWithMediaFields<MT> = MT & {
  media?: ChatMessageUploadFile[];
};

export enum OptionEntityTypes {
  User = 'user',
  Group = 'group',
}

export interface ParticipantLeaderboardAndStreakInfo {
  activeStreakDays: number;
  totalXp: number;
  currentLeagueXp: number;
  currentLeagueType: LeaderboardLeagueType;
}

export interface PaginatedThreadsProps {
  threads: SubscribedThreadFragment[];
  onFetchMoreThreads: () => void;
  isLoadingMore: boolean;
}

/**
 * Keep in sync with client-side query
 * at "frontend/src/controllers/chat/chats.queries.ts"
 * */
export enum ChatGroups {
  Favorite = 'favourite',
  All = 'all',
  Direct = 'direct',
}

export interface MinimalChatForGroupManipulation {
  id: number;
  kind: ChatKind;
  myParticipant?: { isFavourite: boolean } | null;
}

export interface ChatsQueryVariables {
  limit?: number;
  cursor?: number;
  filters?: {
    kinds?: ChatKind[];
    isFavourite?: boolean;
  };
}

export const chatConfigPlaceholder = {
  __typename: 'ChatConfig' as const,
  isThreadsAvailable: true,
  isGuidelinesTabAvailable: false,
  isStatisticsTabAvailable: false,
  allowedMessageTypes: [],
  isCoachViewTogglingAvailable: false,
  isInsertCodeButtonShown: false,
  isNotAnsweredHintShown: false,
  isHeaderShown: true,
  shouldDisplayInputControls: true,
  shouldDisplayMessageControls: true,
  hasMentorSupport: false,
  areGroupTagsDisabled: false,
  shouldDisplayIsTyping: false,
  shouldDisplayQuickActions: false,
  shouldHideProfilePreview: false,
  permissions: [],
};

export enum ChatPermission {
  CreateChatMessage = 'CHAT_MESSAGE::CREATE',
}

export interface ChatRouteQueryParams {
  selectedMessageId?: number;
  threadId?: number;
  fullScreen?: boolean;
  isParticipantsModalOpen?: boolean;
}
