import { type ComponentType } from 'react';

interface SetDisplayName{
  (
    OriginalComponent: ComponentType<any>,
    WrappedComponent: ComponentType<any>,
    prefix: string
  ): void;
}
export const setDisplayName: SetDisplayName = (
  PageComponent,
  WrappedComponent,
  prefix,
) => {
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Page';

    Object.assign(WrappedComponent, {
      displayName: `${prefix}(${displayName})`,
    });
  }
};
