import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Select } from '@/components/ui/FormElements/FormInputs/Select';
import {
  type GoogleAddressInput,
} from '@/components/ui/FormElements/FormInputs/GoogleCitySelector/googleCitySelector.typedef';
import {
  googleCitiesMockData,
} from '@/components/ui/FormElements/FormInputs/GoogleSelectorMock/GoogleSelectorMockData';
import styles from './GoogleSelectorMock.module.scss';

export const GoogleCitySelectorMock: FC<any> = memo((props) => {
  const changeHandler = useCallback((option, previous) => {
    const isValueExist = Boolean(option?.value);
    const isHandleChangeExist = Boolean(props?.handleChange);
    const isValueChanged = option?.value !== previous?.value;

    if (!isValueExist || !isHandleChangeExist || !isValueChanged) {
      return;
    }

    const selectedLocation = googleCitiesMockData.find((location) => (
      option.value === location.value
    ));

    if (!selectedLocation) {
      throw Error(`Location ${option.label}: ${option.value} wasn't added to mock data`);
    }

    const place: GoogleAddressInput = {
      regionName: selectedLocation.regionName,
      googleCityId: selectedLocation.googleCityId,
      countryName: selectedLocation.countryName,
      cityName: selectedLocation.cityName,
    };

    props.handleChange(place);
  }, [props]);

  return (
    <>
      <Select
        {...props}
        options={googleCitiesMockData}
        className={styles.optionsPositionTop}
      />
      <OnChange name={props.name}>
        {changeHandler}
      </OnChange>
    </>
  );
});
