import { type InMemoryCache, type Resolver } from '@apollo/client';
import { type ToggleUserVacancySidebarStateMutationVariables } from '@/controllers/vacancies/vacancies.hooks/useUserVacancySidebarState';
import { userVacancySidebarState } from '@/controllers/platform/userVacancySidebar/userVacancySidebar.queries';

export const UserVacancySidebarResolvers = {
  Mutation: {
    toggleUserVacancySidebar: async (
      parent: Resolver,
      { open }: ToggleUserVacancySidebarStateMutationVariables,
      { cache }: { cache: InMemoryCache },
    ) => {
      cache.writeQuery({
        query: userVacancySidebarState,
        data: {
          userVacancySidebarState: {
            open,
            __typename: 'UserVacancySidebar',
          },
        },
      });

      return open;
    },
  },
};
