import { type FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { confirmModalVar } from '@/controllers/confirm/confirm.cache';
import { ConfirmModal } from '@/components/ui/ConfirmModal';

export const Confirm: FC = () => {
  const {
    onCancel,
    onConfirm,
    onConfirmUnsecure,
    isOpen,
    cancelText,
    confirmText,
    title,
    body,
    secure,
    renderChildren,
    reverseButtons,
    renderCloseButton,
    type,
    renderTitleIcon,
    orderLevel,
  } = useReactiveVar(confirmModalVar);

  return (
    <ConfirmModal
      onConfirmUnsecure={onConfirmUnsecure}
      isOpen={isOpen}
      onRequestClose={onCancel}
      onConfirm={onConfirm}
      onCancel={onCancel}
      body={body}
      title={title}
      confirmText={confirmText}
      cancelText={cancelText}
      secure={secure}
      renderChildren={renderChildren}
      reverseButtons={reverseButtons}
      renderCloseButton={renderCloseButton}
      type={type}
      renderTitleIcon={renderTitleIcon}
      orderLevel={orderLevel}
    />
  );
};
