import {
  type ChangeEvent,
  type FC,
  type JSX,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { cn } from '@/lib/classNames';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Button, ButtonMode, ButtonSize } from '@/components/ui/Button';
import styles from '@/components/ui/ConfirmModal/ConfirmModal.module.scss';
import { typography } from '@/components/ui/typography';
import { InputTextUI } from '@/components/ui/FormElements/FormInputs/InputText';
import { Modal, type Props as ModalProps, PopUpTypeMode } from '@/components/ui/Modal';
import { emptyFunction, exists } from '@/lib/helpers/functional';
import { type IntRange } from '@/lib/helpers/utility-types';

interface Props extends ModalProps {
  onConfirm: () => void;
  onConfirmUnsecure?: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  title?: string;
  body?: string;
  renderConfirmButton?: () => JSX.Element;
  confirmText?: string;
  renderCancelButton?: () => JSX.Element;
  cancelText?: string | null;
  buttonSize?: ButtonSize;
  reverseButtons?: boolean;
  secure?: boolean;
  renderChildren?: () => void;
  renderCloseButton?: boolean;
  type?: PopUpTypeMode;
  orderLevel?: IntRange<1, 5>;
  confirmButtonDataQa?: string;
  cancelButtonDataQa?: string;
}

const CONFIRM_PHRASE = 'confirm';

export const ConfirmModal: FC<Props> = (props) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    onConfirm,
    onConfirmUnsecure,
    onCancel,
    isLoading = false,
    title = t(`${I18N_CODES.common}:confirm-modal.title`),
    body = t(`${I18N_CODES.common}:confirm-modal.body`),
    confirmText = t(`${I18N_CODES.common}:confirm-modal.confirm`),
    confirmButtonDataQa,
    cancelText = t(`${I18N_CODES.common}:confirm-modal.cancel`),
    cancelButtonDataQa,
    renderCancelButton,
    renderConfirmButton,
    reverseButtons = false,
    buttonSize = ButtonSize.Large,
    secure = false,
    children,
    renderChildren,
    renderCloseButton,
    type,
    orderLevel,
    ...rest
  } = props;

  const [confirmPhraseValue, setConfirmPhraseValue] = useState<string>('');
  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setConfirmPhraseValue(target.value);
    },
    [],
  );

  const isConfirmBlocked = useMemo(() => (
    secure
      && confirmPhraseValue.trim().toLowerCase() !== CONFIRM_PHRASE
  ), [confirmPhraseValue, secure]);

  const handleConfirm = useMemo(() => {
    if (onConfirmUnsecure) {
      return onConfirmUnsecure;
    }

    if (!isConfirmBlocked) {
      return onConfirm;
    }

    return emptyFunction;
  }, [isConfirmBlocked, onConfirm, onConfirmUnsecure]);

  const confirmButton = (
    <Button
      mode={reverseButtons
        ? ButtonMode.Secondary
        : ButtonMode.Primary}
      onClick={handleConfirm}
      isLoading={isLoading}
      disabled={isConfirmBlocked}
      text={confirmText}
      className='align-center'
      data-qa={confirmButtonDataQa || 'confirm-button'}
      size={buttonSize}
    />
  );

  const cancelButton = useMemo(() => (
    cancelText === null
      ? <div />
      : (
        <Button
          mode={reverseButtons
            ? ButtonMode.Primary
            : ButtonMode.Secondary}
          onClick={onCancel}
          text={cancelText}
          className='align-center'
          data-qa={cancelButtonDataQa || 'cancel-button'}
          size={buttonSize}
        />
      )
  ), [
    cancelText,
    onCancel,
    reverseButtons,
    cancelButtonDataQa,
    buttonSize,
  ]);

  const renderTitle = useCallback(() => (
    <h2 data-qa="confirm-modal-title" className={cn(styles.title, typography.platformH2)}>
      {title}
    </h2>
  ), [title]);

  const isDefaultModal = type === PopUpTypeMode.Website;

  return (
    <Modal
      type={type}
      renderCloseButton={isDefaultModal || renderCloseButton || false}
      renderTitle={renderTitle}
      overlayClassName={cn(
        styles.overlay,
        exists(orderLevel) && styles[`level-${orderLevel}`],
      )}
      shouldCloseOnEsc
      {...rest}
    >
      {
        (renderChildren && renderChildren())
          || children
          || (
            <p
              data-qa='confirm-body'
              className={cn(typography.platformTextMain, typography.paragraph, 'mb-24', 'text-center')}
            >
              {body}
            </p>
          )
      }

      {secure && (
        <InputTextUI
          placeholder={t(`${I18N_CODES.common}:confirm-modal.confirm-placeholder`, {
            confirmPhrase: CONFIRM_PHRASE,
          })}
          value={confirmPhraseValue}
          onChange={handleChange}
          className="mb-24"
          data-qa="confirm-placeholder"
        />
      )}
      <div
        className={cn(
          styles.buttons,
          { [styles.reverseButtons]: reverseButtons },
          { [styles.buttonsDefault]: isDefaultModal },
        )}
      >
        {!isDefaultModal && (renderCancelButton?.() || cancelButton)}
        {renderConfirmButton?.() || confirmButton}
      </div>
    </Modal>
  );
};
