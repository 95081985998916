import {
  type ForwardedRef,
  forwardRef,
  useMemo,
} from 'react';
import { cn } from '@/lib/classNames';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { ButtonBody } from '@/components/ui/Button/ButtonBody';
import { Loader } from '@/components/ui/Loader';
import {
  type AnchorElementProps,
  type ButtonElementProps, type ButtonORAnchorCommonAttributes,
} from '@/components/ui/Button/typedefs';
import { ButtonElement } from '@/components/ui/Button/ButtonElement';
import { AnchorElement } from '@/components/ui/Button/AnchorElement';
import styles from './Button.module.scss';

export enum ButtonMode {
  Primary = 'primary',
  Secondary = 'secondary',
  BrandPrimary = 'brand-primary',
  BrandSecondary = 'brand-secondary',
  Green = 'green',
  TransparentDark = 'transparent-dark',
  TransparentLight = 'transparent-light',
  Neutral = 'neutral',
}

export enum ButtonStyling {
  Gray = 'gray',
}

export enum ButtonSize {
  Small = 'small',
  Large = 'large',
}

export const modeClassNames: Record<ButtonMode, string> = {
  [ButtonMode.Primary]: styles.primary,
  [ButtonMode.Secondary]: styles.secondary,
  [ButtonMode.BrandPrimary]: styles.brandPrimary,
  [ButtonMode.BrandSecondary]: styles.brandSecondary,
  [ButtonMode.Green]: styles.green,
  [ButtonMode.TransparentDark]: styles.transparentDark,
  [ButtonMode.TransparentLight]: styles.transparentLight,
  [ButtonMode.Neutral]: styles.neutral,
};

export const stylingClassNames: Record<ButtonStyling, string> = {
  [ButtonStyling.Gray]: styles.grayStyling,
};

export const sizeClassNames: Record<ButtonSize, string> = {
  [ButtonSize.Small]: styles.small,
  [ButtonSize.Large]: styles.large,
};

const loaderSizes: Record<ButtonSize, number> = {
  [ButtonSize.Small]: 20,
  [ButtonSize.Large]: 30,
};

interface CustomProps {
  /** Describes the visual appearance of the button */
  mode?: ButtonMode;
  /** Describes the size of the button */
  size?: ButtonSize;
  /** Function Component Icon on the left side, typically used to render SVG icons. */
  LeftIcon?: FCIcon;
  /** Function Component Icon on the right side, typically used to render SVG icons. */
  RightIcon?: FCIcon;
  /** Describes the text of the button */
  text?: string;
  /** Describes the loading state of the button */
  isLoading?: boolean;
  /** Function to render a custom left icon */
  renderLeftIcon?: () => void;
  /** Function to render a custom right icon */
  renderRigthIcon?: () => void;
  /** If 'true', button will have 100% width */
  hasFullWidth?: boolean;
  /** If 'true' the client navigation is enabled. If 'false', page will be reloaded */
  isInternalLink?: boolean;
  /** Function to be called on button click. Doesn't accept any arguments, like 'event' */
  onClickNoArgs?: () => void;
  /** Data attribute for testing purposes */
  dataQa?: string;
}

interface TransparentLightButtonProps extends CustomProps {
  mode: ButtonMode.TransparentLight;
  /** Applicable only for TransparentLight button mode */
  styling?: ButtonStyling;
}

interface OtherButtonProps extends CustomProps {
  mode?: Exclude<ButtonMode, ButtonMode.TransparentLight>;
  styling?: never;
}

type CustomButtonProps = TransparentLightButtonProps | OtherButtonProps;

export type ButtonProps = ButtonORAnchorCommonAttributes
  & AnchorElementProps
  & ButtonElementProps
  & CustomButtonProps;

type RefType = HTMLButtonElement | HTMLAnchorElement;

const ButtonTemplate = forwardRef<RefType, ButtonProps>((props, ref) => {
  const {
    mode = ButtonMode.Primary,
    size = ButtonSize.Small,
    className,
    LeftIcon,
    RightIcon,
    text,
    isLoading = false,
    // Anchor props
    download,
    href,
    hrefLang,
    media,
    ping,
    rel,
    target,
    referrerPolicy,
    // Button props
    autoFocus,
    form,
    formAction,
    formEncType,
    formMethod,
    formNoValidate,
    formTarget,
    name,
    value,
    disabled = false,
    type = 'button',
    renderLeftIcon,
    renderRigthIcon,
    hasFullWidth,
    styling,
    isInternalLink,
    // Rest props
    onClickNoArgs,
    ...rest
  } = props;

  const loaderSize = loaderSizes[size];

  const hasIcon = !!(
    LeftIcon || RightIcon || renderLeftIcon || renderRigthIcon
  );
  const hasOnlyIcon = hasIcon
      && !((LeftIcon || renderLeftIcon) && (RightIcon || renderRigthIcon))
      && !text;

  const body = useMemo(
    () => (
      <ButtonBody
        renderLeftIcon={renderLeftIcon}
        renderRigthIcon={renderRigthIcon}
        LeftIcon={LeftIcon}
        RightIcon={RightIcon}
        text={text}
        hasOnlyIcon={hasOnlyIcon}
      />
    ),
    [
      LeftIcon,
      RightIcon,
      text,
      hasOnlyIcon,
      renderRigthIcon,
      renderLeftIcon,
    ],
  );

  const child = useMemo(() => (
    isLoading
      ? (
        <>
          <span className={styles.transparentContent}>
            {body}
          </span>
          <Loader
            loading={isLoading}
            size={loaderSize}
            className={styles.loader}
            spinnerClassName={styles.spinner}
          />
        </>
      )
      : body
  ), [isLoading, body, loaderSize]);

  const rootClassName = cn(
    modeClassNames[mode],
    sizeClassNames[size],
    styling
      ? stylingClassNames[styling]
      : {},
    className,
    styles.button,
    {
      [styles.onlyIcon]: hasOnlyIcon,
      [styles.withIcon]: hasIcon,
      [styles.fullWidth]: hasFullWidth,
    },
  );

  return href && !disabled
    ? (
      <AnchorElement
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={rootClassName}
        download={download}
        href={href}
        hrefLang={hrefLang}
        media={media}
        ping={ping}
        rel={rel}
        target={target}
        isInternalLink={isInternalLink}
        referrerPolicy={referrerPolicy}
        {...rest}
      >
        {child}
      </AnchorElement>
    )
    : (
      <ButtonElement
        ref={ref as ForwardedRef<HTMLButtonElement>}
        type={type}
        disabled={disabled || isLoading}
        isLoading={isLoading}
        className={rootClassName}
        autoFocus={autoFocus}
        form={form}
        formAction={formAction}
        formEncType={formEncType}
        formMethod={formMethod}
        formNoValidate={formNoValidate}
        formTarget={formTarget}
        name={name}
        value={value}
        onClickNoArgs={onClickNoArgs}
        {...rest}
      >
        {child}
      </ButtonElement>
    );
});

/**
 * Primary component for user interaction. Can act as a button or a link
 * if 'href' attribute is passed
 * */
export const Button = Object.assign(
  ButtonTemplate,
  {
    mode: ButtonMode,
    size: ButtonSize,
    styling: ButtonStyling,
  },
);
