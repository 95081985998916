import {
  type SubDomainFeatureConfig,
} from '@/controllers/features/subDomainFeature.typedefs';
import { type SubDomains } from '@/constants/subdomains';
import SUB_DOMAIN_FEATURES from '@/controllers/features/subDomainFeature.config';

const getIsSubDomainFeatureEnabled = (
  featureConfig: SubDomainFeatureConfig,
  subDomain: string,
): boolean => {
  const { filter } = featureConfig;

  if ('enabledIn' in filter) {
    return filter.enabledIn.includes(subDomain as SubDomains);
  }

  if ('disabledIn' in filter) {
    return !filter.disabledIn.includes(subDomain as SubDomains);
  }

  return false;
};

type FeaturesData = {[key: string]: boolean};

interface Options {
  dbFeaturesData: FeaturesData;
  subDomain: string;
}

export const initSubDomainFeatures = (options: Options): FeaturesData => {
  const { dbFeaturesData, subDomain } = options;

  const subdomainFeaturesData = SUB_DOMAIN_FEATURES.config.reduce<
      FeaturesData
    >(
      (acc, config) => {
        const isSubDomainEnabled = getIsSubDomainFeatureEnabled(
          config,
          subDomain,
        );
        const dbFeatureValue = dbFeaturesData[config.name];

        const isEnabled = dbFeatureValue !== undefined
          ? isSubDomainEnabled && dbFeatureValue
          : isSubDomainEnabled;

        // eslint-disable-next-line no-param-reassign
        acc[config.name] = isEnabled;

        return acc;
      },
      {},
    );

  return {
    ...dbFeaturesData,
    ...subdomainFeaturesData,
  };
};
