import {
  type FC,
  type ReactChild,
  memo,
  useEffect,
  useState,
} from 'react';

interface Props {
  onSSR?: ReactChild;
}

const DefaultOnSSR = () => (<span />);

export const NoSSR: FC<Props> = memo((props) => {
  const [canRender, setCanRender] = useState(false);

  const { children, onSSR = <DefaultOnSSR /> } = props;

  useEffect(() => {
    setCanRender(true);
  }, []);

  return (
    <>
      {canRender
        ? children
        : onSSR}
    </>
  );
});
