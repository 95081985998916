import {
  professionSlug,
  str,
  int,
  oneOf,
} from '@/controllers/router/type-safe-routes/parsers';
import { LMSEditorTestTaskType } from '@/components/platform/LmsEditor/LmsEditor.typedefs';

const addTaskQuery = [
  professionSlug.optional('professionSlug'),
  str.optional('moduleSlug'),
  str.optional('topicSlug'),
  oneOf(
    ...Object.values(LMSEditorTestTaskType),
  ).optional('type'),
];

export const lmsEditor = {
  path: ['learn', 'edit'],
  children: {
    add: {
      path: ['add'],
      children: {
        course: {
          path: ['course'],
          query: [str.optional('professionType')],
        },
        attachmentTask: {
          path: ['task-attachment'],
          query: addTaskQuery,
        },
        quiz: {
          path: ['quiz'],
          query: addTaskQuery,
        },
        githubTask: {
          path: ['task-github'],
          query: addTaskQuery,
        },
        codeEditorTask: {
          path: ['task-code-editor'],
          query: addTaskQuery,
        },
        markupTask: {
          path: ['task-markup'],
          query: addTaskQuery,
        },
      },
    },
    editTask: {
      path: ['edit-task', str('flow')],
      query: [
        int.optional('taskId'),
        str.optional('type'),
        str.optional('professionSlug'),
      ],
    },
    profession: {
      path: [professionSlug('professionSlug')],
      children: {
        courseTasks: {
          path: ['course-tasks'],
          children: {
            morningTasks: {
              path: ['morning-tasks'],
            },
            assignmentTasks: {
              path: ['assignment-tasks'],
            },
            liveCodingTasks: {
              path: ['live-coding-tasks'],
            },
            testTaskPreviews: {
              path: ['task-previews'],
            },
          },
        },
        module: {
          path: [str('moduleSlug')],
          children: {
            topic: {
              path: [str('topicSlug')],
              children: {
                videos: {
                  path: ['videos'],
                  children: {
                    video: {
                      path: [int('learnVideoId')],
                      children: {
                        versions: {
                          path: ['versions'],
                        },
                      },
                    },
                  },
                },
                theory: {
                  path: ['theory'],
                  children: {
                    theoryItem: {
                      path: [int('theoryId')],
                    },
                  },
                },
                tasks: {
                  path: ['tasks'],
                  children: {
                    addExisting: {
                      path: ['add-existing'],
                    },
                  },
                },
                versions: {
                  path: ['versions'],
                },
              },
            },
          },
        },
      },
    },
  },
};
