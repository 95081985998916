import { cn } from '@/lib/classNames';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Mate/IconMateLogoCircle.module.scss';

/**
 * Mate logo circle icon
 * @deprecated Use LogoCircle instead
 * @private This component is not for direct use
 */
export const IconMateLogoCircle: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={cn(className, styles.logoIcon)}
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="mate-logo-circle-fill">
      <path
        d="M0.166504 14C0.166504 6.6362 6.13604 0.666664 13.4998 0.666664C20.8636 0.666664 26.8332 6.6362 26.8332 14C26.8332 21.3638 20.8636 27.3333 13.4998 27.3333C6.13604 27.3333 0.166504 21.3638 0.166504 14Z"
      />
    </g>

    <g id="mate-logo-circle-text">
      <path
        d="M14.2104 10.0327H12.062L9.90156 13.6415L7.7532 10.0327H5.59277V18.4814H7.7532V13.9432L9.79294 17.3227H10.0102L12.062 13.9312V18.4814H14.2104V10.0327Z"
      />
      <path
        d="M19.7284 12.4466V13.0018C19.3422 12.5432 18.787 12.2777 18.0146 12.2777C16.3973 12.2777 15.1783 13.6536 15.1783 15.464C15.1783 17.2744 16.3973 18.6503 18.0146 18.6503C18.787 18.6503 19.3422 18.3848 19.7284 17.9262V18.4814H21.732V12.4466H19.7284ZM18.4611 16.7796C17.7128 16.7796 17.1939 16.2485 17.1939 15.464C17.1939 14.6795 17.7128 14.1484 18.4611 14.1484C19.2095 14.1484 19.7284 14.6795 19.7284 15.464C19.7284 16.2485 19.2095 16.7796 18.4611 16.7796Z"
      />
    </g>
  </BaseIcon>
);
