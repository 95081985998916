import { memo } from 'react';
import { cn } from '@/lib/classNames';
import styles from './Loader.module.scss';

export enum LoaderStyle {
  Warning = 'Warning',
  Info = 'Info',
}

const classNameByLoaderStyle: Record<LoaderStyle, string> = {
  [LoaderStyle.Warning]: styles.warning,
  [LoaderStyle.Info]: styles.info,
};

interface Props {
  size?: number;
  loading?: boolean;
  className?: string;
  spinnerClassName?: string;
  opacity?: number;
  loaderStyle?: LoaderStyle;
  dataQa?: string;
}

export const Loader = memo<Props>((props) => {
  const {
    size = 15,
    loading = false,
    className = '',
    spinnerClassName = '',
    opacity = 1,
    loaderStyle,
    dataQa = 'loader',
  } = props;

  if (!loading) {
    return null;
  }

  const containerClass = cn(
    className,
    styles.container,
    'loader',
    loaderStyle
      ? classNameByLoaderStyle[loaderStyle]
      : '',
  );

  return (
    <div
      data-qa={dataQa}
      className={containerClass}
      style={{
        opacity,
      }}
    >
      <div
        className={cn(spinnerClassName, styles.spinner)}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderWidth: `${Math.ceil(size / 10)}px`,
        }}
      />
    </div>
  );
});
