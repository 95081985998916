import { type Reference } from '@apollo/client';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  ChatWithAuthParticipantMinimalFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/chatWithAuthParticipantMinimal.generated';
import {
  useChatCreatedSubscription,
} from '@/components/platform/Chat/graphql/generated/chatCreated.subscription.generated';
import { QUERY_FILTERS_PER_GROUP } from '@/components/platform/Chat/chat.constants';
import { ChatGroups } from '@/components/platform/Chat/chat.typedefs';
import { ChatCacheHelper } from '@/components/platform/Chat/cache.helpers/chat.helper';

export const useChatCreated = () => {
  useChatCreatedSubscription({
    skip: !isBrowser,
    onData: ({
      client,
      data: { data },
    }) => {
      const store = client.cache;
      const newChat = data?.chatCreated;

      if (!newChat) {
        return;
      }

      new ChatCacheHelper(store).addChatToList(newChat);

      store.modify({
        fields: {
          chats(existingChats, { readField, storeFieldName }) {
            const alreadyExist = (
              Array.isArray(existingChats) && existingChats.some(
                (chat: Reference) => readField('id', chat) === newChat.id,
              )
            );

            if (alreadyExist) {
              return existingChats;
            }

            const sameKind = storeFieldName.includes(newChat.kind);
            const bothFavourite
              = !!newChat.myParticipant?.isFavourite
              && storeFieldName.includes(
                JSON.stringify(QUERY_FILTERS_PER_GROUP[ChatGroups.Favorite]),
              );

            if (!sameKind || !bothFavourite) {
              return existingChats;
            }

            const newChatRef = store.writeFragment({
              data: newChat,
              fragment: ChatWithAuthParticipantMinimalFragmentDoc,
              fragmentName: 'ChatWithAuthParticipantMinimal',
            });

            return [newChatRef, ...existingChats];
          },
        },
      });
    },
  });
};
