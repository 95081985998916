import { type ApolloCache } from '@apollo/client';
import {
  ChatMentionCountersDocument,
  type ChatMentionCountersQuery,
} from '@/components/platform/Chat/graphql/generated/chatMentionCounters.query.generated';

export class MentionCacheHelper {
  store: ApolloCache<any>;

  constructor(store: ApolloCache<any>) {
    this.store = store;
  }

  public incrementGlobalChatMentionsCount() {
    this.store.modify({
      fields: {
        unreadChatMentionsCount: (currentCount: number) => (
          (currentCount || 0) + 1
        ),
      },
    });
  }

  public decrementGlobalChatMentionsCount(delta = 1) {
    this.store.modify({
      fields: {
        unreadChatMentionsCount: (currentCount: number) => (
          Number(currentCount) - delta >= 0
            ? Number(currentCount) - delta
            : 0
        ),
      },
    });
  }

  public setGlobalChatMentionsCount(count: number) {
    this.store.modify({
      fields: {
        unreadChatMentionsCount: () => count,
      },
    });
  }

  public getChatMentionsCount(chatId: number): number {
    const mentionsQuery = this.store.readQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
    });

    if (!mentionsQuery) {
      return 0;
    }

    const recordToUpdate = mentionsQuery.chatMentionCounters.find(
      (record) => record.chatId === chatId,
    );

    return recordToUpdate?.count || 0;
  }

  public setChatUnreadMentionsCount(chatId: number, count: number) {
    this.store.modify({
      id: this.store.identify({
        __typename: 'GroupChat',
        id: chatId,
      }),
      fields: {
        unreadMentionsCount: () => count,
      },
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'PrivateChat',
        id: chatId,
      }),
      fields: {
        unreadMentionsCount: () => count,
      },
    });
  }

  public incrementChatUnreadMentionsCount(
    chatId: number,
  ) {
    this.store.modify({
      id: this.store.identify({
        __typename: 'GroupChat',
        id: chatId,
      }),
      fields: {
        unreadMentionsCount: (currentCount) => (currentCount || 0) + 1,
      },
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'PrivateChat',
        id: chatId,
      }),
      fields: {
        unreadMentionsCount: (currentCount) => (currentCount || 0) + 1,
      },
    });
  }

  public decrementChatUnreadMentionsCount(
    chatId: number,
  ) {
    this.store.modify({
      id: this.store.identify({
        __typename: 'GroupChat',
        id: chatId,
      }),
      fields: {
        unreadMentionsCount: (currentCount) => (
          Number(currentCount) - 1 >= 0
            ? Number(currentCount) - 1
            : 0
        ),
      },
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'PrivateChat',
        id: chatId,
      }),
      fields: {
        unreadMentionsCount: (currentCount) => (
          Number(currentCount) - 1 >= 0
            ? Number(currentCount) - 1
            : 0
        ),
      },
    });
  }

  public incrementChatMentionsCounter(chatId: number) {
    const mentionsQuery = this.store.readQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
    });

    if (!mentionsQuery) {
      return;
    }

    const recordToUpdate = mentionsQuery.chatMentionCounters.find(
      (record) => record.chatId === chatId,
    );

    const updatedRecords = [
      ...(mentionsQuery.chatMentionCounters || []).filter(
        (record) => record.chatId !== chatId,
      ),
      {
        chatId,
        count: (recordToUpdate?.count || 0) + 1,
      },
    ];

    this.store.writeQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
      data: {
        chatMentionCounters: updatedRecords,
      },
    });
  }

  public decrementChatMentionsCounter(chatId: number) {
    const mentionsQuery = this.store.readQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
    });

    if (!mentionsQuery) {
      return;
    }

    const recordToUpdate = mentionsQuery.chatMentionCounters.find(
      (record) => record.chatId === chatId,
    );

    if (!recordToUpdate) {
      return;
    }

    const updatedRecords = [
      ...(mentionsQuery.chatMentionCounters || []).filter(
        (record) => record.chatId !== chatId,
      ),
      {
        chatId,
        count: recordToUpdate.count - 1,
      },
    ];

    this.store.writeQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
      data: {
        chatMentionCounters: updatedRecords,
      },
    });
  }

  public setChatMentionsCounter(chatId: number, count: number) {
    const mentionsQuery = this.store.readQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
    });

    if (!mentionsQuery?.chatMentionCounters) {
      return;
    }

    const updatedRecords = mentionsQuery.chatMentionCounters.map(
      (record) => (
        record.chatId === chatId
          ? { ...record, count }
          : record
      ),
    );

    this.store.writeQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
      data: {
        chatMentionCounters: updatedRecords,
      },
    });
  }

  public resetChatMentionsCounter(chatId: number) {
    const mentionsQuery = this.store.readQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
    });

    if (!mentionsQuery) {
      return;
    }

    const updatedRecords = (mentionsQuery.chatMentionCounters || []).filter(
      (record) => record.chatId !== chatId,
    );

    this.store.writeQuery<ChatMentionCountersQuery>({
      query: ChatMentionCountersDocument,
      data: {
        chatMentionCounters: updatedRecords,
      },
    });
  }
}
