import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconDiamond: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.77778 1.33325H3.11111L0 5.33325H4.44444L5.77778 1.33325Z" fill="#BDDDF4" />
    <path d="M16.0004 5.33325L12.8893 1.33325H10.2227L11.556 5.33325H16.0004Z" fill="#5DADEC" />
    <path d="M11.5556 5.33325H16L8 14.6666L11.5556 5.33325Z" fill="#4289C1" />
    <path d="M4.44444 5.33325H0L8 14.6666L4.44444 5.33325ZM5.77778 1.33325L4.44444 5.33325H11.5556L10.2222 1.33325H5.77778Z" fill="#8CCAF7" />
    <path d="M8.00087 14.6666L4.44531 5.33325H11.5564L8.00087 14.6666Z" fill="#5DADEC" />
  </BaseIcon>
);
