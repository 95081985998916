import { type InMemoryCache, type Resolver } from '@apollo/client';
import { sidebarState } from '@/controllers/platform/sidebar/sidebar.queries';
import { type ToggleSidebarMutationVariables } from '@/controllers/platform/sidebar/sidebar.typedefs';

export const SidebarResolvers = {
  Mutation: {
    toggleSidebar: async (
      parent: Resolver,
      { open }: ToggleSidebarMutationVariables,
      { cache }: { cache: InMemoryCache },
    ) => {
      cache.writeQuery({
        query: sidebarState,
        data: {
          sidebarState: {
            sidebarOpen: open,
            __typename: 'SidebarState',
          },
        },
      });

      return open;
    },
  },
};
