import { memo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { LogoProductMap } from '@/components/common/Logo/constants';
import { Products } from '@/constants/products';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const LogoProduct: FCIcon = memo((props) => {
  const { productName } = useSubDomainContext();

  const Logo = LogoProductMap[productName ?? Products.Mate];

  return <Logo {...props} />;
});
