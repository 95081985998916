import { Logger, type LoggerOptions, LogLevels } from '@mate-academy/logger';
import { getApiLink } from '@/middleware/helpers/getApiLink';
import { userDeviceInfo } from '@/lib/helpers/userDeviceInfo';
import { currentUrl } from '@/lib/helpers/currentUrl';

class RestLogger extends Logger {
  private static service = 'frontend';

  private static apiPath = getApiLink({ path: 'rest/logger' });

  private static params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(options: LoggerOptions) {
    super(options);
  }

  async debug(...logs: any[]): Promise<void> {
    await this.send(logs, LogLevels.Debug);
  }

  async info(...logs: any[]): Promise<void> {
    await this.send(logs, LogLevels.Info);
  }

  async warning(...logs: any[]): Promise<void> {
    await this.send(logs, LogLevels.Warning);
  }

  async error(...logs: any[]): Promise<void> {
    await this.send(logs, LogLevels.Error);
  }

  child(name: string): RestLogger {
    return new RestLogger({
      userId: this.userId,
      parents: [...this.parents, name],
      getTraceId: this.getTraceId,
    });
  }

  private async send(logs: any[], level: LogLevels) {
    const extras = {
      ...currentUrl(),
      ...userDeviceInfo(),
    };

    const message = this.prepareLogs(logs, level, extras);

    const body = JSON.stringify({
      message,
      service: RestLogger.service,
      type: level,
    });

    const canSendViaBeacon = (
      typeof navigator !== 'undefined' && Boolean(navigator.sendBeacon)
    );

    if (canSendViaBeacon) {
      try {
        const data = new Blob([body], { type: 'application/json' });

        const isScheduled = navigator.sendBeacon(
          RestLogger.apiPath,
          data,
        );

        if (isScheduled) {
          return;
        }
      } catch (error) {
        // do nothing
      }
    }

    try {
      await fetch(RestLogger.apiPath, {
        ...RestLogger.params,
        body,
      });
    } catch (error) {
      // do nothing
    }
  }
}

const logger = new RestLogger({
  name: 'global',
});

export {
  logger,
};
