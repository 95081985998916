import React, { type FC, memo, useEffect } from 'react';
import { type YouTubePlayer } from '@/components/platform/Learn/pages/CourseTopic/typedefs/YouTubePlayer';
import { emptyFunction } from '@/lib/helpers/functional';

interface Props {
  onReady: (event: { target: YouTubePlayer }) => void;
  [key: string]: any;
}

const playerMock: YouTubePlayer = {
  playVideo: emptyFunction,
  pauseVideo: emptyFunction,
  stopVideo: emptyFunction,
  getCurrentTime: emptyFunction,
  cueVideoById: emptyFunction,
  loadVideoById: emptyFunction,
  player: null,
};

export const YouTubeMock: FC<Props> = memo((props) => {
  const {
    onReady,
  } = props;

  useEffect(() => {
    onReady({ target: playerMock });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-qa="YouTubeMock"
      style={{
        width: '640px',
        height: '480px',
        backgroundColor: 'green',
      }}
    />
  );
});
