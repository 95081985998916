import React, { type ComponentType, useEffect } from 'react';
import { type AppProps } from 'next/app';
import { makeVar } from '@apollo/client';
import { type PageProps } from '@/controllers/page/page.typedefs';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import { DEFAULT_SUB_DOMAIN, type SUB_DOMAINS } from '@/middleware/i18n/i18n.config';
import { i18nParseLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { Products } from '@/constants/products';
import { getRequestHost } from '@/controllers/subDomain/subDomain.utils/getRequestHost';
import { getProductFromHost } from '@/controllers/subDomain/subDomain.utils/getProductFromHost';
import { isKnowelyProduct } from '@/controllers/subDomain/subDomain.utils/isKnowelyProduct';
import type { AppGetInitialProps, MateAppProps } from '../../../pages/_app';
import { SubDomainProvider } from './subDomain.context';

export const subDomainVar = makeVar<string>(
  DEFAULT_SUB_DOMAIN,
);
export const productNameVar = makeVar<Products>(Products.Mate);

interface ComponentProps {
  /* eslint-disable react/require-default-props */
  subDomain?: SUB_DOMAINS;
  productName?: Products;
  /* eslint-enable react/require-default-props */
}

interface WithSubDomainApp {
  (
    App: ComponentType<MateAppProps> & { getInitialProps?: AppGetInitialProps }
  ): ComponentType<MateAppProps>;
}

export const withSubDomainApp: WithSubDomainApp = (App) => {
  const WithSubDomainApp = (props: AppProps<PageProps> & ComponentProps) => {
    const subDomain = props.subDomain ?? DEFAULT_SUB_DOMAIN;
    const { productName } = props;

    productNameVar(productName ?? Products.Mate);

    useEffect(() => {
      subDomainVar(subDomain);
    }, [subDomain]);

    const ensuredProductName = productName ?? Products.Mate;

    return (
      <SubDomainProvider subDomain={subDomain} productName={ensuredProductName}>
        <App {...props} />
      </SubDomainProvider>
    );
  };

  const getInitialProps: AppGetInitialProps = async (ctx) => {
    const { ctx: { locale, req } } = ctx;

    const host = req
      ? getRequestHost(req)
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      : window.location.host;

    const hostProduct = getProductFromHost(host);

    const productLocale = isKnowelyProduct(hostProduct)
      ? 'en-us'
      : locale;

    let initialProps = {};

    const subDomain = i18nParseLanguageSubpath(productLocale).domain;

    Object.assign(ctx.ctx, { subDomain });

    if (App.getInitialProps) {
      initialProps = await App.getInitialProps(ctx);
    }

    return {
      ...initialProps,
      subDomain,
    };
  };

  WithSubDomainApp.getInitialProps = getInitialProps;

  setDisplayName(App, WithSubDomainApp, 'withSubDomainApp');

  return WithSubDomainApp;
};
