import { memo } from 'react';
import styles from './InputPlaceholder.module.scss';

export const InputPlaceholder = memo(() => (
  <div
    data-qa='input-placeholder'
    className={styles.inputPlaceholder}
  >
    Loading...
  </div>
));
