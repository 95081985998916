import { type ApolloCache, type ApolloClient } from '@apollo/client';
import {
  ChatMinimalByIdDocument,
  type ChatMinimalByIdQuery,
} from '@/components/platform/Chat/graphql/generated/chatMinimalById.query.generated';
import {
  CHAT_GROUPS_QUERY_KEYS,
  INITIAL_QUERY_VARIABLES,
} from '@/components/platform/Chat/chat.constants';
import {
  ChatGroupsDocument,
  type ChatGroupsQuery,
} from '@/components/platform/Chat/graphql/generated/chatGroups.query.generated';
import { ensureChatInGroup } from '@/components/platform/Chat/store.helpers';
import { ChatKind } from '@/controllers/graphql/generated';
import { SidebarChatsDocument, type SidebarChatsQuery } from '@/components/platform/Chat/graphql/generated/sidebarChats.query.generated';
import { type ChatListItem } from '@/components/platform/Chat/domain/Chat/ChatListItem';

export class ChatCacheHelper {
  store: ApolloCache<any>;

  constructor(store: ApolloCache<any>) {
    this.store = store;
  }

  public async addChatToGroupIfNotExists(
    client: ApolloClient<any>,
    chatId: number,
  ) {
    const existingChats = this.store.readQuery<ChatGroupsQuery>({
      query: ChatGroupsDocument,
      variables: INITIAL_QUERY_VARIABLES,
    });

    if (!existingChats) {
      return;
    }

    const isChatAlreadyInList = Object.values(CHAT_GROUPS_QUERY_KEYS)
      .some((key) => (
        existingChats[key].some(({ id }) => id === chatId)
      ));

    if (isChatAlreadyInList) {
      return;
    }

    const loadedChat = await this.loadChat(client, chatId);

    if (!loadedChat || loadedChat?.kind === ChatKind.Service) {
      return;
    }

    ensureChatInGroup(client, loadedChat);
  }

  public async loadChatAndAddToList(
    client: ApolloClient<any>,
    chatId: number,
  ) {
    const chatsQuery = this.store.readQuery<SidebarChatsQuery>({
      query: SidebarChatsDocument,
    });

    if (!chatsQuery) {
      return;
    }

    const isChatAlreadyInList = chatsQuery.userChats.some(
      ({ id }) => id === chatId,
    );

    if (isChatAlreadyInList) {
      return;
    }

    const loadedChat = await this.loadChat(client, chatId);

    if (
      !loadedChat
        || loadedChat?.kind === ChatKind.Service
        || loadedChat.__typename !== 'GroupChat'
    ) {
      return;
    }

    this.store.writeQuery<SidebarChatsQuery>({
      query: SidebarChatsDocument,
      data: {
        userChats: [
          loadedChat,
          ...chatsQuery.userChats,
        ],
      },
    });
  }

  public removeChatFromList(chatId: number) {
    const chatsQuery = this.store.readQuery<SidebarChatsQuery>({
      query: SidebarChatsDocument,
    });

    if (!chatsQuery) {
      return;
    }

    this.store.writeQuery<SidebarChatsQuery>({
      query: SidebarChatsDocument,
      data: {
        userChats: chatsQuery.userChats.filter(({ id }) => id !== chatId),
      },
    });
  }

  public addChatToList(chat: ChatListItem) {
    const chatsQuery = this.store.readQuery<SidebarChatsQuery>({
      query: SidebarChatsDocument,
    });

    if (!chatsQuery) {
      return;
    }

    const isChatAlreadyInList = chatsQuery.userChats.some(
      ({ id }) => id === chat.id,
    );

    if (isChatAlreadyInList) {
      return;
    }

    this.store.writeQuery<SidebarChatsQuery>({
      query: SidebarChatsDocument,
      data: {
        userChats: [
          chat,
          ...chatsQuery.userChats,
        ],
      },
    });
  }

  private async loadChat(client: ApolloClient<any>, chatId: number) {
    const { data } = await client.query<ChatMinimalByIdQuery>({
      query: ChatMinimalByIdDocument,
      variables: {
        chatId,
      },
    });

    return data.chat || null;
  }
}
