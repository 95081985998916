/* eslint-disable deprecation/deprecation */
import { memo } from 'react';
import {
  IconMateLogoFull,
} from '@/components/ui/icons/product/Mate';
import { type FCIcon } from '@/components/ui/icons/typedefs';

/**
 * Mate logo full icon
 * @deprecated Use LogoFull instead
 * @private This component is not for direct use
 */
export const MateLogoFull: FCIcon = memo((props) => (
  <IconMateLogoFull {...props} />
));
