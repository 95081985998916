import {
  createContext,
  useContext,
  useMemo,
  memo,
} from 'react';
import i18Next from '@/middleware/i18n';
import {
  createFeaturesHandler,
  mapFeatures,
} from '@/controllers/features/features.helpers';
import {
  type Feature,
  type FeaturesData,
  type FeaturesHandler,
  type FeaturesQuery,
} from '@/controllers/features/features.typedefs';
import { useGetFeaturesQuery } from '@/controllers/features/generated/getFeatures.query.generated';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  initSubDomainFeatures,
} from '@/controllers/features/features.helpers/initSubDomainFeatures';
import { EMPTY_ARRAY } from '@/constants';

interface FeaturesContextType {
  featuresHandler: FeaturesHandler;
}

type FeaturesContextHook = () => FeaturesContextType;

const FeaturesContext = createContext<FeaturesContextType>({
  featuresHandler: createFeaturesHandler({
    featuresData: EMPTY_ARRAY as unknown as FeaturesData,
  }),
});

export const FeaturesContextProvider = memo(({
  children,
}) => {
  const { query: queryString } = i18Next.useRouter();
  const { data } = useGetFeaturesQuery();
  const { subDomain } = useSubDomainContext();

  const featuresData = useMemo(() => {
    const dbFeatures = data?.allFeatures || EMPTY_ARRAY;
    const dbFeaturesData = mapFeatures(
        dbFeatures as Feature[],
        queryString as FeaturesQuery,
    );

    return initSubDomainFeatures({
      dbFeaturesData,
      subDomain,
    });
  }, [data?.allFeatures, queryString, subDomain]);

  const value = useMemo(
    () => ({
      featuresHandler: createFeaturesHandler({ featuresData }),
    }),
    [featuresData],
  );

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  );
});

export const useFeaturesContext: FeaturesContextHook = () => (
  useContext(FeaturesContext)
);
