import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconMedalBronze: FCIcon = (props) => (
  <BaseIcon
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_53086_7030)">
      <path d="M14.833 6.22222L9.38856 0H0.833008L11.7219 13.2222L20.6827 9.52778L14.833 6.22222Z" fill="#E25544" />
      <path d="M20.2775 0L14.833 6.22222L19.0252 11.9093L19.9796 10.7512L28.833 0H20.2775Z" fill="#E77062" />
      <path d="M18.8811 12.4647C18.9434 12.2959 18.983 12.1162 18.983 11.9257C18.983 11.067 18.2861 10.3701 17.4275 10.3701H12.242C11.3826 10.3701 10.6865 11.067 10.6865 11.9257C10.6865 12.1162 10.7254 12.2959 10.7884 12.4647C8.25435 13.8849 6.53857 16.5923 6.53857 19.7034C6.5378 24.2853 10.2525 28 14.8344 28C19.4162 28 23.1309 24.2853 23.1309 19.7034C23.1309 16.5923 21.4151 13.8849 18.8811 12.4647Z" fill="#EBA178" />
      <path d="M11.8162 22.8277L12.7324 21.9115C12.8335 21.8088 12.9797 21.7948 13.0956 21.8819C13.0956 21.8819 13.9971 22.5516 14.6947 22.5516C15.3208 22.5516 15.961 22.0281 15.961 21.388C15.961 20.7479 15.3061 20.2105 14.2304 20.2105H13.7334C13.5887 20.2105 13.4581 20.0798 13.4581 19.9344V18.7272C13.4581 18.567 13.5887 18.4511 13.7334 18.4511H14.2304C15.2485 18.4511 15.786 17.9572 15.786 17.3319C15.786 16.7058 15.2485 16.2415 14.6224 16.2415C13.9823 16.2415 13.5755 16.5759 13.3577 16.8225C13.2551 16.9391 13.0816 16.9391 12.9657 16.838L12.0783 15.9654C11.9608 15.8627 11.9756 15.6892 12.0783 15.5874C12.0783 15.5874 13.2706 14.3071 14.855 14.3071C16.542 14.3071 17.9964 15.34 17.9964 16.9687C17.9964 18.0879 17.1525 18.917 16.4984 19.2219V19.2662C17.1821 19.5571 18.2577 20.4586 18.2577 21.7092C18.2577 23.2944 16.8764 24.4875 14.869 24.4875C13.0381 24.4875 12.1063 23.5852 11.8014 23.1932C11.7135 23.089 11.7291 22.9288 11.8162 22.8277Z" fill="#7C4119" />
    </g>
    <defs>
      <clipPath id="clip0_53086_7030">
        <rect width="28" height="28" fill="white" transform="translate(0.833008)" />
      </clipPath>
    </defs>
  </BaseIcon>
);
