import { EMPTY_ARRAY } from '@/constants';
import { type FormFields } from '@/components/ui/ChatInput/chatInput.typedefs';
import {
  SUBJECT_EMPTY,
} from '@/components/platform/Chat/components/Input/MessageSubjectSelector/MessageSubjectSelector.constants';

export const MAX_INPUT_LENGTH = 5000;
export const WARNING_INPUT_LENGTH = MAX_INPUT_LENGTH - 100;
export const MAX_INPUT_LENGTH_FOR_GUIDELINES = 10000;
export const MAX_AI_ASSISTANT_INPUT_LENGTH = 200;
export const MAX_OPTION_LENGTH = 500;
export const WARNING_OPTION_LENGTH = MAX_OPTION_LENGTH - 50;
export const DEFAULT_INPUT_HEIGHT = 24;
export const MAX_INPUT_HEIGHT = 240;
export const WRAP_CODE_BACKTICKS_COUNT = 4;
export const MAX_INPUT_COUNTER = Number(
  `${MAX_INPUT_LENGTH}`.replaceAll(/\d/g, '9'),
);
export const IMAGE_DIMENSION = 62;

export const ALLOWED_IMAGES = ['image/x-png', 'image/png', 'image/jpeg'];
export const ALLOWED_FILES = ['application/pdf'];

export const DEFAULT_INITIAL_VALUES: FormFields = {
  text: '',
  uploadFiles: EMPTY_ARRAY,
  subject: SUBJECT_EMPTY,
  options: [
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
  ],
};
