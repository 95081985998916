import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconMateAcademyStroke: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 12.9961C3 8.02553 7.02944 3.99609 12 3.99609C16.9706 3.99609 21 8.02553 21 12.9961C21 17.9667 16.9706 21.9961 12 21.9961C7.02944 21.9961 3 17.9667 3 12.9961Z" stroke="currentColor" strokeWidth="2" />
    <path d="M12.5329 10.0207H10.9217L9.30136 12.7272L7.69009 10.0207H6.06976V16.3571H7.69009V12.9535L9.21989 15.4881H9.38282L10.9217 12.9445V16.3571H12.5329V10.0207Z" fill="currentColor" />
    <path d="M16.6715 11.8311V12.2475C16.3818 11.9035 15.9655 11.7043 15.3861 11.7043C14.1731 11.7043 13.2589 12.7363 13.2589 14.0941C13.2589 15.4519 14.1731 16.4838 15.3861 16.4838C15.9655 16.4838 16.3818 16.2847 16.6715 15.9407V16.3571H18.1742V11.8311H16.6715ZM15.721 15.0808C15.1598 15.0808 14.7706 14.6825 14.7706 14.0941C14.7706 13.5057 15.1598 13.1074 15.721 13.1074C16.2823 13.1074 16.6715 13.5057 16.6715 14.0941C16.6715 14.6825 16.2823 15.0808 15.721 15.0808Z" fill="currentColor" />
  </BaseIcon>
);
