import { makeVar } from '@apollo/client';
import { type ThreadUpdate } from '@/controllers/subscribedThreadUpdates/subscribedThreadUpdates.typedefs';

interface UnreadUpdatesReactiveState {
  unreadUpdates: ThreadUpdate[];
}

interface ThreadUpdatesReactiveState {
  threadUpdates: ThreadUpdate[];
}

export const threadUpdatesVar = makeVar<ThreadUpdatesReactiveState>({
  threadUpdates: [],
});

export const unreadUpdatesVar = makeVar<UnreadUpdatesReactiveState>({
  unreadUpdates: [],
});
