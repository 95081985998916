import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  memo,
} from 'react';
import { cn } from '@/lib/classNames';
import FormStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  dataQa?: string;
};

export const InputTextUI = memo<Props>((
  props,
) => {
  const { className, dataQa, ...rest } = props;

  return (
    <input
      className={cn(className, FormStyles.input)}
      type="text"
      data-qa={dataQa}
      {...rest}
    />
  );
});

export const InputText = withFinalFormController<string>()<Props>(InputTextUI);
