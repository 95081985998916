/* eslint-disable deprecation/deprecation */
import { memo } from 'react';
import { IconKnowelyLogoCircle } from '@/components/ui/icons/product/Knowely/IconKnowelyLogoCircle';
import { type FCIcon } from '@/components/ui/icons/typedefs';

/**
 * Knowely logo circle icon
 * @deprecated Use LogoCircle instead
 * @private This component is not for direct use
 */
export const KnowelyLogoCircle: FCIcon = memo((props) => (
  <IconKnowelyLogoCircle {...props} />
));
