import { useMemo } from 'react';
import { type BaseFlashMessage, type FlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { flashMessageVar } from '@/controllers/flashMessage/flashMessage.cache';
import { FLASH_MESSAGE_BACKFILL } from '@/controllers/flashMessage/flashMessages.constants';

let messageCounter = 0;

export const showMessage = async <D = any>(message: BaseFlashMessage<D>) => {
  const { visibleMessages } = flashMessageVar();

  const messageWithId: FlashMessage<D> = {
    id: messageCounter + 1,
    ...FLASH_MESSAGE_BACKFILL,
    ...message,
  };

  messageCounter += 1;

  flashMessageVar({ visibleMessages: [...visibleMessages, messageWithId] });

  return messageWithId;
};

export const removeMessage = async (message: FlashMessage) => {
  const { visibleMessages } = flashMessageVar();

  const filteredMessages = visibleMessages.filter(
    (visibleMessage) => visibleMessage.id !== message.id,
  );

  flashMessageVar({ visibleMessages: filteredMessages });
};

export const useFlashMessage = () => useMemo(
  () => ({ showMessage, removeMessage }),
  [],
);
