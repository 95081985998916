import { type CourseType, type File, type StudentStatus } from '@/controllers/graphql/generated';
import {
  type HomeworkDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/Homework/homeworkDashboard.typedefs';
import {
  type MorningTaskDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/MorningTask/morningTaskDashboard.typedefs';
import {
  type EnglishDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/English/englishDashboard.types';
import {
  type AttendanceDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/Attendance/attendanceDashboard.typedefs';
import {
  type TechCheckDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/TechCheck/techCheckDashboard.typedefs';
import {
  type SummaryDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/Summary/summaryDashboard.typedefs';
import {
  type AutoTechCheckDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/AutoTechCheck/autoTechCheckDashboard.typedefs';
import {
  type ApplyingDashboardStudent,
} from '@/components/platform/GroupDashboard/pages/Applying/applyingDashboard.typedefs';
import {
  type DashboardBaseGroupQueryVariables,
} from '@/components/platform/GroupDashboard/layers/Communication/graphql/generated/dashboardsBaseGroup.query.generated';

export interface StudentsGroupForDashboard {
  id: number;
  name: string;
  courseId?: number | null;
  courseSlug?: string | null;
  daysToSolveMorningTasks: number;
  startDate?: Date | null;
}

export interface StudentForDashboard {
  id: number;
  username: string;
  avatar: File | null;
  isEmployed: boolean;
  email?: string;
  workingCompany?: string | null | undefined;
  studentStatus?: StudentStatus | null;
}

export type StudentForDashboardWithStatistics = StudentForDashboard & (
  Pick<HomeworkDashboardStudent, 'homeWorkStatistics'>
  & Pick<MorningTaskDashboardStudent, 'morningTaskStatistics'>
  & Pick<AttendanceDashboardStudent, 'attendanceStatistics'>
  & Pick<EnglishDashboardStudent, 'englishStatistics'>
  & Pick<TechCheckDashboardStudent, 'techCheckStatistic'>
  & Pick<SummaryDashboardStudent, 'summaryStatistics'>
  & Pick<AutoTechCheckDashboardStudent, 'autoTechCheckStatistic'>
  & Pick<ApplyingDashboardStudent, 'applyingStatistic' | 'workingCompany'>
);

export interface TaskDeadline {
  date: Date | null;
  placeholder: string;
}

export enum GroupDashboardTab {
  Homework = 'homework',
  MorningTasks = 'morning_tasks',
  Attendance = 'attendance',
  English = 'english',
  Typing = 'typing',
  TechCheck = 'tech_check',
  FlexTechCheck = 'flex_tech_check',
  Summary = 'summary',
  AutoTechCheck = 'auto_tech_check',
  Applying = 'applying',
}

export enum GroupDashboardTabUrl {
  Homework = 'progress',
  MorningTasks = 'morning-tasks',
  Attendance = 'attendance',
  English = 'english',
  TechCheck = 'tech-check',
  Summary = 'summary',
  AutoTechCheck = 'auto-tech-check',
  Applying = 'applying',
  FlexTechCheck = 'flex-tech-check',
}

export interface GroupPageQuery {
  tab: GroupDashboardTabUrl;
  groupName: string;
}

export interface UserWithChatId {
  id: number;
  username: string;
  chatId: number | null;
}

export type AvailableCourseType = CourseType.Prepaid | CourseType.Postpaid;

export type SettingsByCourseType = {
  availableTabs: GroupDashboardTab[];
  indexTab: GroupDashboardTabUrl;
  studentStatusesToExclude: StudentStatus[];
};

export type UseStudentsGroupOptions = {
  groupName: string;
  studentStatusesToExclude: StudentStatus[];
};

export type UseStudentsGroupQueryOptions = {
  variables: DashboardBaseGroupQueryVariables;
  skip: boolean;
};

export enum TechCheckScoreType {
  Success = 'Success',
  Warning = 'Warning',
  Critical = 'Critical',
  Unknown = 'Unknown',
}
