import { SECOND } from '@/constants';
import {
  ChatGroups, ChatListFilter, ChatPermission, MessageType, MessageWSType,
} from '@/components/platform/Chat/chat.typedefs';
import { I18N_CODES } from '@/lib/constants/general';
import { ChatKind, ChatSectionType, ParticipantRole } from '@/controllers/graphql/generated';
import { type ChatGroupsQuery } from '@/components/platform/Chat/graphql/generated/chatGroups.query.generated';
import { MAX_INPUT_LENGTH } from '@/components/ui/ChatInput/chatInput.constants';

export const MINIMAL_DESKTOP_WIDTH = 1440;
export const MINIMAL_TABLET_WIDTH = 1024;

export enum Device {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

export const CHAT_HEADER_HEIGHT = 40;
export const CHAT_LIST_LIMIT = 20;

export const CHATS_PER_REQUEST_LIMIT = 20;

export const SUBSCRIBED_THREADS_LIMIT = 5;

export const PARTICIPANTS_LIMIT = 15;

export const SEARCH_PARTICIPANTS_LIMIT = 15;
export const MINIMAL_SEARCH_QUERY_LENGTH = 2;

export const CHAT_PAGINATION_LIMIT = 20;
export const FAVOURITE_CHATS_INITIAL_LIMIT = 20;
export const ALL_CHATS_INITIAL_LIMIT = 15;
export const DIRECT_CHATS_INITIAL_LIMIT = 15;

export const MENTION_TRIGGER_REGEX = /(?:^|\s|[([{}\])])(@([^@\s]+(\s[^@\s]*)?))$/;
export const DRAFT_MESSAGE_SAVE_DELAY = SECOND;
export const DRAFT_MESSAGE_MAX_LENGTH = MAX_INPUT_LENGTH * 2;
export const SEARCH_PARTICIPANTS_DEBOUNCE = 300;

export const CHAT_KEYBOARD_SHORTCUTS = {
  sender: 'b',
};

export const SCHEDULED_MESSAGES_LIMIT = 10;

export const SAVED_MESSAGES_LIMIT = 10;

export const MAX_UNREAD_MESSAGES_COUNT = 99;

export const CHAT_SECTION_NAME_MAX_LENGTH = 80;

export const CHAT_SECTION_TITLE_CODES = {
  [ChatSectionType.Favorite]: `${I18N_CODES.chat}:chats_list.favorite_chats_tab`,
  [ChatSectionType.All]: `${I18N_CODES.chat}:chats_list.all_chats_tab`,
  [ChatSectionType.Direct]: `${I18N_CODES.chat}:chats_list.direct_chats_tab`,
  [ChatSectionType.Lesson]: `${I18N_CODES.chat}:chats_list.lesson_chats_tab`,
};

export const CHAT_SECTION_DATA_ID = {
  [ChatSectionType.Favorite]: 'favourite-chat-section',
  [ChatSectionType.All]: 'all-chat-section',
  [ChatSectionType.Direct]: 'direct-chat-section',
  [ChatSectionType.Lesson]: 'lesson-chat-section',
  [ChatSectionType.Custom]: 'custom-chat-section',
};

export const CHAT_GROUPS_QUERY_KEYS: Record<
  ChatGroups,
  keyof Omit<ChatGroupsQuery, '__typename'>
> = {
  [ChatGroups.Favorite]: 'favouriteChats',
  [ChatGroups.All]: 'allChats',
  [ChatGroups.Direct]: 'directChats',
};

export const QUERY_FILTERS_PER_GROUP = {
  [ChatGroups.Favorite]: {
    isFavourite: true,
  },
  [ChatGroups.All]: {
    kinds: [ChatKind.Regular],
    isFavourite: false,
  },
  [ChatGroups.Direct]: {
    kinds: [ChatKind.Direct],
    isFavourite: false,
  },
};

export const INITIAL_QUERY_VARIABLES = {
  favouriteChatsLimit: FAVOURITE_CHATS_INITIAL_LIMIT,
  allChatsLimit: ALL_CHATS_INITIAL_LIMIT,
  directChatsLimit: DIRECT_CHATS_INITIAL_LIMIT,
};

export const CONFIG_PRESETS = {
  messageCreatingRestricted: {
    permissions: [{
      permission: ChatPermission.CreateChatMessage,
      roles: [ParticipantRole.Admin],
    }],
  },
  messageCreatingAllowed: {
    permissions: [{
      permission: ChatPermission.CreateChatMessage,
      roles: Object.values(ParticipantRole),
    }],
  },
};

export enum DraggableItems {
  SidebarSection = 'sidebar-section',
}

export enum DropAreaPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export const MessageWSToCommonTypeMap = {
  [MessageWSType.Thread]: MessageType.Thread,
  [MessageWSType.Message]: MessageType.Message,
  [MessageWSType.OpenQuestion]: MessageType.OpenQuestion,
  [MessageWSType.OpenQuestionAnswer]: MessageType.OpenQuestionAnswer,
  [MessageWSType.Poll]: MessageType.Poll,
  [MessageWSType.PollAnswer]: MessageType.PollAnswer,
  [MessageWSType.SystemMessage]: MessageType.SystemMessage,
  [MessageWSType.DraftMessage]: MessageType.DraftMessage,
};

export const CHAT_LIST_FILTER_CODES = {
  [ChatListFilter.AllActivity]: `${I18N_CODES.chat}:chats_list.filter.all_activity`,
  [ChatListFilter.UnreadsOnly]: `${I18N_CODES.chat}:chats_list.filter.unreads_only`,
  [ChatListFilter.MentionsOnly]: `${I18N_CODES.chat}:chats_list.filter.mentions_only`,
};
