import { type MinimumArray } from '@/lib/helpers/utility-types';

export function last<T>(arr: [T, ...T[]]): T;
export function last<T>(arr: T[]): T | undefined;
export function last<T>(arr: T[]): T | undefined {
  return arr[arr.length - 1];
}

export function randomSort<T>(array: T): T;
export function randomSort<T>(array: T[]): T[] {
  return [...array].sort(
    () => Math.random() - 0.5,
  );
}

export function map<T extends any[], U extends any[]>(
  arr: T,
  fn: (el: T[number], i: number) => U[number],
): U;
export function map<T, U>(arr: T[], fn: (el: T, i: number) => U): U[];
export function map<T extends any[], U>(
  arr: T,
  fn: (el: any, i: number) => U,
): U[] {
  return arr.map(fn);
}

export function getRandomArrayElement<T = any>(array: MinimumArray<1, T>): T {
  const index = Math.floor(Math.random() * array.length);

  return array[index] as T;
}
