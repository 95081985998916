import {
  type InferPathParams, type InferQueryParams, replace, type WithContext,
} from 'typesafe-routes';
import { type OptionalDeep } from '@/typedefs/common';

export function replaceRouteParams<R extends WithContext>(
  route: R,
  asPath: string,
  params: OptionalDeep<InferQueryParams<R> & InferPathParams<R>>,
): string {
  return replace(route, asPath, {
    path: params,
    query: params,
  });
}
