import { type GrantedPermission, type PermissionWithConfig } from '@/controllers/graphql/generated';
import { type SelectOption } from '@/components/ui/FormElements/FormInputs/Select';

export enum PermissionsEntityRoutes {
  Role = 'roles',
  SubscriptionGrants = 'subscription-grants',
  Courses = 'courses',
  EnglishLevel = 'english-level',
  User = 'user',
  Domain = 'domain',
}

interface PermissionParentDomain {
  id: number;
  slug: string;
  countryName: string;
}

export interface PermissionsParent {
  id: number;
  name: string;
  domains: PermissionParentDomain[];
}

export interface PermissionsWithParent {
  parent: PermissionsParent;
  permissions: GrantedPermission[];
}

export interface PermissionsQueries {
  tab: PermissionsEntityRoutes;
  entityId: string;
}

interface EditablePermissionResources {
  any?: boolean;
  slug?: SelectOption<string>[];
}

export interface EditableGrantedPermission {
  id: number;
  permission: string;
  resources: EditablePermissionResources;
}

export interface EditPermissionsFormFields {
  grantedPermissions?: EditableGrantedPermission[];
}

export interface EditUserPermissionsFormFields {
  userId?: SelectOption<number>;
  userInfo?: PermissionsUserInfo;
  grantedPermissions: EditableGrantedPermission[];
}

export enum EditPermissionsFieldNames {
  GrantedPermissions = 'grantedPermissions',
  UserInfo = 'userInfo',
  UserRoles = 'userInfo.userRoles',
  UserSubscriptionType = 'userInfo.subscriptionType',
  UserSubscriptionGrant = 'userInfo.subscriptionGrant',
  UserEnglishLevel = 'userInfo.englishLevel',
  UserCourse = 'userInfo.courseName',
}

export enum AddPermissionFieldName {
  SelectedPermissionWithConfig = 'selectedPermissionWithConfig',
  PermissionTemplateName = 'permissionTemplateName',
  PermissionTemplateResources = 'permissionTemplateResources',
  IsPermissionGrantedToAllResources = 'isPermissionGrantedToAllResources',
}

export interface AddPermissionFormFields {
  [AddPermissionFieldName.SelectedPermissionWithConfig]: SelectOption<
    PermissionWithConfig
  >;
  [AddPermissionFieldName.PermissionTemplateName]: SelectOption<string>;
  [AddPermissionFieldName.PermissionTemplateResources]: SelectOption<string>[];
  [AddPermissionFieldName.IsPermissionGrantedToAllResources]: boolean;
}

export type UserRolesSelectOptions = SelectOption<string>[];

export interface PermissionsUserInfo {
  id: number;
  userRoles: UserRolesSelectOptions;
  englishLevel?: SelectOption<string>;
  subscriptionType?: SelectOption<string>;
  subscriptionGrant?: SelectOption<number>;
  courseName?: SelectOption<string>;
}
