import { type ApolloCache, type Reference } from '@apollo/client';
import { type Modifiers } from '@apollo/client/cache/core/types/common';
import {
  type MessageReactionStackFragment,
  MessageReactionStackFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/messageReactionStack.fragment.generated';

interface CreateStackOptions {
  id: string;
  messageId: number;
  reactionShortcode: string;
  authParticipantReacted?: boolean;
  createdAt?: number;
}

interface ModifyReactionsCountOptions {
  isAuthUser?: boolean;
}

export class ReactionCacheHelper {
  private store: ApolloCache<any>;

  constructor(store: ApolloCache<any>) {
    this.store = store;
  }

  public createStack(options: CreateStackOptions) {
    const { id, messageId } = options;

    const createdStackRef = this.store.writeFragment({
      id: `MessageReactionStack:${id}`,
      fragment: MessageReactionStackFragmentDoc,
      data: {
        __typename: 'MessageReactionStack',
        ...options,
        count: 1,
        authParticipantReacted: options.authParticipantReacted || false,
        createdAt: options.createdAt || Date.now(),
      },
    });

    if (!createdStackRef) {
      return;
    }

    this.addStackToMessage(createdStackRef, messageId);
  }

  public incrementStackReactionsCount(
    stackId: string,
    { isAuthUser }: ModifyReactionsCountOptions = {},
  ) {
    const modifiers: Modifiers = {
      count: (count = 0) => Number(count) + 1,
      authParticipantReacted: (prevValue = false) => isAuthUser || prevValue,
    };

    this.modifyStack(stackId, modifiers);
  }

  public decrementStackReactionsCount(
    stackId: string,
    { isAuthUser }: ModifyReactionsCountOptions = {},
  ) {
    const modifiers: Modifiers = {
      count: (count = 0) => Math.max(Number(count) - 1, 0),
      authParticipantReacted: (prevValue = false) => (
        isAuthUser
          ? false
          : prevValue
      ),
    };

    this.modifyStack(stackId, modifiers);
  }

  public hasAuthUserReacted(stackId: string) {
    return this.getStackById(stackId)?.authParticipantReacted || false;
  }

  public getStackById(stackId: string) {
    const stack = this.store.readFragment<MessageReactionStackFragment>({
      id: `MessageReactionStack:${stackId}`,
      fragment: MessageReactionStackFragmentDoc,
    });

    return stack || null;
  }

  private addStackToMessage(stackRef: Reference, messageId: number) {
    const modifiers: Modifiers = {
      reactionStacks: (existingStacks) => (
        Array.isArray(existingStacks)
          ? [...existingStacks, stackRef]
          : [stackRef]
      ),
    };

    this.modifyMessage(messageId, modifiers);
  }

  public deleteStack(stackId: string, messageId: number) {
    this.deleteStackFromMessage(stackId, messageId);

    this.store.evict({
      id: this.store.identify({
        __typename: 'MessageReactionStack',
        id: stackId,
      }),
    });
  }

  private deleteStackFromMessage(stackId: string, messageId: number) {
    const modifiers: Modifiers = {
      reactionStacks: (existingStacks, { readField }) => (
        Array.isArray(existingStacks)
          ? existingStacks.filter((stackRef) => readField('id', stackRef) !== stackId)
          : []
      ),
    };

    this.modifyMessage(messageId, modifiers);
  }

  private modifyStack(stackId: string, modifiers: Modifiers) {
    this.store.modify({
      id: this.store.identify({
        __typename: 'MessageReactionStack',
        id: stackId,
      }),
      fields: modifiers,
    });
  }

  private modifyMessage(messageId: number, modifiers: Modifiers) {
    this.store.modify({
      id: this.store.identify({
        __typename: 'Thread',
        id: messageId,
      }),
      fields: modifiers,
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'Message',
        id: messageId,
      }),
      fields: modifiers,
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'OpenQuestion',
        id: messageId,
      }),
      fields: modifiers,
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'OpenQuestionAnswer',
        id: messageId,
      }),
      fields: modifiers,
    });

    this.store.modify({
      id: this.store.identify({
        __typename: 'Poll',
        id: messageId,
      }),
      fields: modifiers,
    });
  }
}
