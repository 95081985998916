import { useSubscribedThreadUpdateReceived } from '@/components/platform/Chat/hooks/useSubscribedThreadUpdateReceived';
import { useChatCreated } from '@/components/platform/Chat/hooks/useChatCreated';
import { useChatMessagePinned } from '@/components/platform/Chat/hooks/useChatMessagePinned';
import { useChatParticipantEntered } from '@/components/platform/Chat/hooks/useChatParticipantEntered';
import { useChatParticipantStatusChanged } from '@/components/platform/Chat/hooks/useChatParticipantStatusChanged';
import { useChatUpdated } from '@/components/platform/Chat/hooks/useChatUpdated';
import { useThreadReplyRead } from '@/components/platform/Chat/hooks/useThreadReplyRead';
import { useSoundNotificationSent } from '@/components/platform/Chat/hooks/useSoundNotificationSent';
import { useChatMessageSaved } from '@/components/platform/Chat/hooks/useChatMessageSaved';
import { useChatMessageCreatedWS } from '@/components/platform/Chat/data.hooks/useChatMessageCreatedWS';
import { useChatMessageUpdatedWS } from '@/components/platform/Chat/data.hooks/useChatMessageUpdatedWS';
import { useOpenQuestionAnswerRatedWS } from '@/components/platform/Chat/data.hooks/useOpenQuestionAnswerRatedWS';
import { useChatMessageDeletedWS } from '@/components/platform/Chat/data.hooks/useChatMessageDeletedWS';
import { useUnreadChatMessageDeletedWS } from '@/components/platform/Chat/data.hooks/useUnreadChatMessageDeletedWS';
import { useUnreadThreadReplyDeletedWS } from '@/components/platform/Chat/data.hooks/useUnreadThreadReplyDeletedWS';
import { useChatMessageUnpinnedWS } from '@/components/platform/Chat/data.hooks/useChatMessageUnpinnedWS';
import { useChatMessageUnsavedWS } from '@/components/platform/Chat/data.hooks/useChatMessageUnsavedWS';
import { useChatMentionCreatedWS } from '@/components/platform/Chat/data.hooks/useChatMentionCreatedWS';
import { useChatMentionDeletedWS } from '@/components/platform/Chat/data.hooks/useChatMentionDeletedWS';
import { useReactionAddedToStack } from '@/components/platform/Chat/data.hooks/reaction.hooks/useReactionAddedToStack';
import {
  useReactionDeletedFromStack,
} from '@/components/platform/Chat/data.hooks/reaction.hooks/useReactionDeletedFromStack';
import { useReactionStackCreated } from '@/components/platform/Chat/data.hooks/reaction.hooks/useReactionStackCreated';
import { useReactionStackDeleted } from '@/components/platform/Chat/data.hooks/reaction.hooks/useReactionStackDeleted';
import { useChatMessageReadWS } from '@/components/platform/Chat/data.hooks/useChatMessageReadWS';
import { type AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import { useSingleChatMessageReadWS } from '@/components/platform/Chat/data.hooks/useSingleChatMessageReadWS';

export const useChatSubscriptions = (authUser: AuthUserFragment) => {
  useChatCreated();
  useChatUpdated();

  useChatParticipantEntered();
  useChatParticipantStatusChanged();

  useSoundNotificationSent();

  useChatMessageCreatedWS();
  useChatMessageUpdatedWS();

  useOpenQuestionAnswerRatedWS();

  useChatMessageDeletedWS();
  useUnreadChatMessageDeletedWS();
  useUnreadThreadReplyDeletedWS();

  useChatMentionCreatedWS();
  useChatMentionDeletedWS();

  useReactionAddedToStack(authUser.id);
  useReactionDeletedFromStack(authUser.id);
  useReactionStackCreated(authUser.id);
  useReactionStackDeleted(authUser.id);

  useChatMessageReadWS();
  useSingleChatMessageReadWS();
  useThreadReplyRead();

  useChatMessagePinned();
  useChatMessageUnpinnedWS();

  useChatMessageSaved();
  useChatMessageUnsavedWS();

  useSubscribedThreadUpdateReceived();
};
