import React, { type ComponentType } from 'react';
import { ApolloProvider } from '@apollo/client';
import { type AppProps } from 'next/app';
import { LogLevels } from '@mate-academy/logger';
import {
  initApollo,
  initApolloInContext,
} from '@/controllers/apollo/apollo.client';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import { LOGIN_NOT_AUTHORIZED } from '@/lib/constants/errors';
import { redirect } from '@/lib/redirect';
import {
  type ApolloInitialState,
  type ReadyApolloClient,
} from '@/controllers/apollo/apollo.typedefs';
import { errorHandler } from '@/core/ErrorHandler';
import type { AppGetInitialProps, MateAppProps } from '../../../../pages/_app';

interface WithApolloProps {
  /* eslint-disable react/require-default-props */
  apolloClient?: ReadyApolloClient;
  apolloState?: ApolloInitialState;
  /* eslint-enable react/require-default-props */
}

interface WithApolloApp {
  (
    App: ComponentType<MateAppProps > & { getInitialProps?: AppGetInitialProps }
  ): ComponentType<MateAppProps & WithApolloProps>;
}

export const withApolloApp: WithApolloApp = (App) => {
  const WithApolloApp = (props: MateAppProps & WithApolloProps) => {
    const { apolloClient, apolloState } = props;

    const client = apolloClient ?? initApollo({
      initialState: apolloState,
    });

    return (
      <ApolloProvider client={client}>
        <App {...props} apolloClient={client} />
      </ApolloProvider>
    );
  };

  const getInitialProps: AppGetInitialProps = async (ctx) => {
    const {
      Component,
      router,
      ctx: { req, res, AppTree },
    } = ctx;

    const headers = req?.headers ?? {};

    const client = initApolloInContext({ ctx: ctx.ctx, headers });

    let appProps: Partial<AppProps> = {};

    if (App.getInitialProps) {
      appProps = await App.getInitialProps(ctx);
    }

    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    if (typeof window === 'undefined') {
      if (res?.writableEnded || res?.headersSent) {
        return appProps;
      }

      if (AppTree) {
        try {
          const { getDataFromTree } = await import('@apollo/client/react/ssr');

          await getDataFromTree(
            <AppTree
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={client}
              pageProps={{ ...appProps.pageProps, apolloClient: client }}
            />,
          );
        } catch (error) {
          const typedError = error as Error;

          if (typedError.message.includes(LOGIN_NOT_AUTHORIZED)) {
            errorHandler.captureException(typedError, {
              logMessage: 'Not Authorized',
            });

            redirect(ctx.ctx, '/sign-in');
          } else {
            errorHandler.captureException(typedError, {
              logMessage: 'Error while running \'getDataFromTree\'',
              logLevel: LogLevels.Error,
            });
          }
        }
      }
    }

    return {
      ...appProps,
      apolloState: client.cache.extract(),
      apolloClient: client,
    };
  };

  WithApolloApp.getInitialProps = getInitialProps;

  setDisplayName(App, WithApolloApp, 'withApolloApp');

  return WithApolloApp;
};
