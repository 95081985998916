import { type AuthUserQueryResult, useAuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';
import { type AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';

type UseAuthUserHookOutput = [
  AuthUserFragment | null,
  AuthUserQueryResult,
];

interface Options {
  ssr: boolean;
}

export const useAuthUser = ({ ssr }: Options): UseAuthUserHookOutput => {
  // ssr: false - should be used for pages with server-side caching, e.g. landing page
  const queryResult = useAuthUserQuery({ ssr });

  const content = queryResult.data?.authUser || null;

  return [content, queryResult];
};
