import type { ForwardedRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import ReactSelect, {
  type OptionTypeBase,
  type Props as ReactSelectProps,
  type ValueType,
} from 'react-select';
import { cn } from '@/lib/classNames';
import { type Option, type SelectOption } from '@/components/ui/FormElements/FormInputs/Select/Select.typedefs';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { AutoScrollOption } from '@/components/ui/FormElements/FormInputs/Select/AutoScrollOption';
import styles from './Select.module.scss';

export enum MenuWidth {
  ByContent = 'content-width',
  ByControl = 'control-width',
  MaxContent = 'content-max-width',
}

export enum SelectMode {
  Regular = 'regular',
  Borderless = 'borderless',
}

const widthClassNames: Record<MenuWidth, string> = {
  [MenuWidth.ByContent]: styles.menuWidthByContent,
  [MenuWidth.ByControl]: styles.menuWidthByControl,
  [MenuWidth.MaxContent]: styles.menuWidthMaxContent,
};

const modeClassNames: Record<SelectMode, string> = {
  [SelectMode.Regular]: styles.regularMode,
  [SelectMode.Borderless]: styles.borderlessMode,
};

interface CustomProps {
  disabled?: boolean;
  isSearchable?: boolean;
  mode?: SelectMode;
  menuWidth?: MenuWidth;
  dataQa?: string;
  scrollToSelectedOption?: boolean;
}

type Props<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false
> = ReactSelectProps<OptionType, IsMulti> & CustomProps;

export function SelectUi<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false
>(
  props: PropsWithChildren<Props<OptionType, IsMulti>>,
) {
  const {
    className,
    disabled,
    mode = SelectMode.Regular,
    menuWidth = MenuWidth.ByControl,
    isMulti,
    isSearchable,
    scrollToSelectedOption,
    ...rest
  } = props;

  return (
    <ReactSelect<OptionType, IsMulti>
      className={cn(
        styles.select,
        className,
        modeClassNames[mode],
        widthClassNames[menuWidth],
        { [styles.multiSelect]: isMulti },
      )}
      classNamePrefix="ma-select"
      instanceId={props.id}
      inputId={props.id}
      id={undefined}
      isMulti={isMulti}
      isDisabled={disabled}
      isSearchable={isSearchable}
      components={{
        ...(scrollToSelectedOption && { Option: AutoScrollOption }),
      }}
      {...rest}
    />
  );
}

export const SelectWithRef = forwardRef(<
  OptionType extends OptionTypeBase,
  IsMulti extends boolean = false
>(
    props: PropsWithChildren<Props<OptionType, IsMulti>>,
    ref: ForwardedRef<ReactSelect<OptionType, IsMulti>>,
  ) => {
  const {
    className,
    disabled,
    mode = SelectMode.Regular,
    menuWidth = MenuWidth.ByControl,
    isMulti,
    isSearchable,
    ...rest
  } = props;

  return (
    <ReactSelect<OptionType, IsMulti>
      className={cn(
        styles.select,
        className,
        modeClassNames[mode],
        widthClassNames[menuWidth],
        { [styles.multiSelect]: isMulti },
      )}
      classNamePrefix="ma-select"
      instanceId={props.id}
      inputId={props.id}
      id={undefined}
      isMulti={isMulti}
      isDisabled={disabled}
      isSearchable={isSearchable}
      ref={ref}
      {...rest}
    />
  );
});

SelectUi.mode = SelectMode;
SelectUi.menuWidth = MenuWidth;

export const Select = withFinalFormController<
  ValueType<Option, false>
>()(
  SelectUi,
);

export const SelectBoolean = withFinalFormController<
  ValueType<SelectOption<string | number | boolean>, false>
>()(
  SelectUi,
);
