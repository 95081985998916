import { type ApolloClient } from '@apollo/client';
import {
  type LeaderboardUserXpHistoryBaseFragment,
} from '@/components/platform/Learn/graphql/generated/leaderboardUserXpHistoryBase.fragment.generated';
import {
  GetLastUserXpHistoryWithLeaderboardDocument,
  type GetLastUserXpHistoryWithLeaderboardQuery,
  type GetLastUserXpHistoryWithLeaderboardQueryVariables,
} from '@/components/platform/Home/XpChart/communication/graphql/generated/getLastUserXpHistoryWithLeaderboard.generated';

type Options = {
  client: ApolloClient<any>;
  xpHistory: LeaderboardUserXpHistoryBaseFragment;
  authUserId: number;
};

export const updateMonthStreaksQuery = (options: Options) => {
  const { xpHistory, client, authUserId } = options;

  const {
    updatedAt: xpUpdateDate,
    xp: historyXp,
  } = xpHistory;

  client.cache.updateQuery<
    GetLastUserXpHistoryWithLeaderboardQuery,
    GetLastUserXpHistoryWithLeaderboardQueryVariables
  >({
    query: GetLastUserXpHistoryWithLeaderboardDocument,
    variables: {
      daysCount: (new Date()).getDate(),
      userId: authUserId,
    },
  }, (monthXpData) => {
    const monthXp = monthXpData?.leaderboardUserXpPerLastNDays;

    if (!monthXp) {
      return;
    }

    const xpEventDate = (new Date(xpUpdateDate)).getDate();

    const updatedMonthXp = monthXp.map((xpByDay) => {
      const xpDayDate = (new Date(xpByDay.dayDateISOString)).getDate();

      if (xpEventDate === xpDayDate) {
        return {
          ...xpByDay,
          collectedXp: xpByDay.collectedXp + historyXp,
        };
      }

      return xpByDay;
    });

    // eslint-disable-next-line consistent-return
    return {
      leaderboardUserXpPerLastNDays: updatedMonthXp,
    };
  });
};
